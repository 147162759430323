import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import logoImage2 from '../../assets/logo2.png';
import css from './css/Navbar.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const NavBar = () => {
  const [language, setLanguage] = useState('French'); // Initialize the language state 
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  const handleSignIn = () => {
    window.location.href = '/login';
  };

  const navigate = useNavigate();
  const handleNavigation = (path) => () => {
    navigate(path, { replace: true });
  };

  // Function to change language
  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: true, // Whether animation should happen only once
      mirror: false, // Whether elements should animate out while scrolling past them
    });
  }, []);

  return (
    <>
      <header id="header" className="header d-flex align-items-right fixed-top" style={{ backgroundColor: "#103164", height: "90px"}}>
        <div className="container-fluid container-xl position-relative d-flex align-items-center">
          <img src="assets/img/lan.png" alt="" style={{ width: '120px' }} />
          <nav className="navbar navbar-expand-sm navbar-dark" style={{ marginLeft: "auto" }}>
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {language === 'English' ? 'Home' : 'Accueil'}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      {language === 'English' ? 'About Us' : 'À propos de nous'}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services">
                      {language === 'English' ? 'Services' : 'Service'}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact">
                      {language === 'English' ? 'Contact' : 'Contact'}
                    </a>
                  </li>
                  <div className="dropdown">
                    <li className="nav-item" data-bs-toggle="dropdown">
                      <a className="nav-link" href="#" style={{ width: "20px", marginRight: '30px' }}>
                        <i className="bi bi-translate"></i>
                      </a>
                    </li>
                    <ul className="dropdown-menu">
                      <button
                        onClick={() => changeLanguage('English')}
                        style={{ border: "none", }}
                      >
                        <img
                          src="assets/img/england.png"
                          alt="English"
                          style={{ width: "30px", height: "30px", borderRadius: "200px" }} /><b>{language === 'English' ? 'English' : 'Anglais'}</b>
                      </button>
                      <br /><br />
                      <button
                        onClick={() => changeLanguage('French')}
                        style={{ border: "none" }}
                      >
                        <img
                          src="assets/img/franceFLag.png"
                          alt="Français"
                          style={{ width: "30px", height: "30px", borderRadius: "200px" }} /><b>{language === 'English' ? 'French' : 'Francais'}</b>
                      </button>
                    </ul>
                  </div>
                </ul>

                <Button
                  onClick={handleNavigation('/login')}
                  variant="contained"
                  color="primary"
                  style={{ borderRadius: '5px', background: 'green', color: 'white', marginLeft: '30px' }}
                >
                  <span className='signup'>
                    {language === 'English' ? 'Login' : 'Connexion'}
                  </span>
                </Button>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <section className="hero section dark-background" style={{ marginTop: "80px", position: "relative", overflow: "hidden" }}>
  {/* Background Image */}
  <img 
    src="assets/img/world-dotted-map.png" 
    alt="" 
    className="hero-bg" 
    data-aos="fade-in" 
    style={{ 
      position: "absolute", top: 0, left: 0, width: "100%", 
      height: "100%", objectFit: "cover", zIndex: -1 
    }} 
  />
  {/* Dark Overlay */}
  <div 
    className="overlay" 
    style={{ 
      position: "absolute", top: 0, left: 0, width: "100%", 
      height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: -1 
    }} 
  ></div>

  <div className="container" style={{ position: "relative", zIndex: 1 }}>
    <div className="row gy-4 d-flex justify-content-between">
      {/* Text Section */}
      <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
        <h2 data-aos="fade-up" style={{ color: "#fff", fontSize: "2.5rem", fontWeight: "bold" }}>
          {language === 'English' ? 'Transforming logistics into seamless journeys' : 'Transformer la logistique en parcours fluides'}
        </h2>
        <p data-aos="fade-up" data-aos-delay="100" style={{ color: "#ddd", fontSize: "1.2rem" }}>
          {language === 'English' ? 'With our advanced logistics solutions, we make sure your packages arrive on time and safely. Join us to revolutionize the way you navigate logistics your journey starts here.' : 'Avec nos solutions logistiques avancées, nous nous assurons que vos colis arrivent à temps et en sécurité. Rejoignez-nous pour révolutionner votre façon de naviguer dans la logistique votre voyage commence ici.'}
        </p>
      </div>

      {/* Image Section */}
      <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
        <img src="assets/img/landoMap.png" className="img-fluid mb-3 mb-lg-0" alt="" style={{ borderRadius: "10px", width: "800px" }} />
      </div>
    </div>
  </div><br /><br />
  {/* Search Bar Positioned at the Bottom Center */}
  <div 
    className="search-bar text-white p-3 rounded" 
    style={{ 
      position: "absolute", top: "50%", left: "50%", 
      transform: "translate(-50%, 65%)", width: "80%", maxWidth: "500px", 
      textAlign: "center",
      padding: "20px", borderRadius: "10px",
    }}
  >
    
    <h5 style={{marginTop: "70px"}}>{language === 'English' ? 'Track Your Goods' : 'Suivez vos marchandises'}</h5>
    <div className="input-group">
      <input 
        type="text" 
        className="form-control text-primary" 
        placeholder={language === 'English' ? 'Enter Tracking Number' : 'Entrez le numéro de suivi'} 
        aria-label="Tracking Number" 
      />
      <button className="btn" type="button"
      style={{backgroundColor: "#103164", color: "#fff", fontWeight: "bold", letterSpacing: "1px"}}>
        {language === 'English' ? 'Track' : 'Suivre'}
      </button>
    </div>
  </div>
</section>

      <section id="featured-services" className="featured-services section">
        <div className="container">

    <div className="row gy-4">
      <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="100">
        <div className="icon flex-shrink-0"><i className="bi bi-diagram-3"></i></div>
        <div>
          <h4 className="title">{language === 'English' ? 'Our values' : 'Nos valeurs'}</h4>
          <p className="description">{language === 'English' ? 'Whether you are a shipper aiming for fast deliveries or a receiver seeking hassle-free experiences, LANDO LOGISTICS is your partner in success. We believe that every shipment should arrive safely and every interaction should reflect our commitment to excellence.' : 'Que vous soyez un expéditeur visant des livraisons rapides ou un destinataire cherchant des expériences sans tracas, LANDO LOGISTICS est votre partenaire dans la réussite. Nous croyons que chaque expédition doit arriver en toute sécurité et que chaque interaction doit refléter notre engagement envers l\'excellence.'}</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="200">
        <div className="icon flex-shrink-0"><i className="bi bi-fullscreen-exit"></i></div>
        <div>
          <h4 className="title">{language === 'English' ? 'OUR VISION' : 'Notre vision'}</h4>
          <p className="description">{language === 'English' ? 'We envision a logistics landscape where every shipment is seamlessly processed, meticulously tracked, and effortlessly delivered. Our platform is designed to transform the delivery industry by combining cutting-edge technology with a deep understanding of logistical complexities.' : 'Nous imaginons un paysage logistique où chaque expédition est traitée de manière transparente, suivie méticuleusement et livrée sans effort. Notre plateforme est conçue pour transformer l\'industrie de la livraison en combinant la technologie de pointe avec une compréhension profonde des complexités logistiques.'}</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="300">
        <div className="icon flex-shrink-0"><i className="bi bi-broadcast"></i></div>
        <div>
          <h4 className="title">{language === 'English' ? 'What makes us different' : 'Ce qui nous différencie'}</h4>
          <p className="description">{language === 'English' ? 'Unlike traditional logistics solutions, LANDO LOGISTICS transcends conventional practices. We believe that shipments are more than packages and routes. Designed to improve every aspect of shipping and receiving, ensuring that shippers and receivers experience nothing less than excellence.' : 'Contrairement aux solutions logistiques traditionnelles, LANDO LOGISTICS transcende les pratiques conventionnelles. Nous croyons que les expéditions sont plus que des colis et des itinéraires. Conçu pour améliorer chaque aspect de l\'expédition et de la réception, garantissant que les expéditeurs et les destinataires vivent rien de moins que l\'excellence.'}</p>
        </div>
      </div>
    </div>
  </div>
</section>


      <section id="about" className="about section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first" data-aos="fade-up" data-aos-delay="200">
              <img src="assets/img/service-1.jpg" className="img-fluid" alt="" />
              <a href="https://www.instagram.com/lando_global_logistics/reels/" className="glightbox pulsating-play-btn"></a>
            </div>
            <div className="col-lg-6 content order-last  order-lg-first" data-aos="fade-up" data-aos-delay="100">
              <h3>{language === 'English' ? 'About Us' : 'À propos de nous'}</h3>
              <p>{language === 'English' ? 'At LANDO LOGISTICS, our mission is to revolutionize the delivery experience by providing a seamless, user-centric platform that empowers both shippers and receivers. We are not just a logistics solution, but a channel to transform shipments into seamless journeys and aspirations into fulfillment.' : 'Chez LANDO LOGISTICS, notre mission est de révolutionner l\'expérience de livraison en fournissant une plateforme transparente et centrée sur l\'utilisateur qui donne le pouvoir aussi bien aux expéditeurs qu\'aux destinataires. Nous ne sommes pas seulement une solution logistique, mais un canal pour transformer les expéditions en voyages sans heurts et les aspirations en réalisations.'}</p>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services section">
        <div className="container section-title" data-aos="fade-up">
          <span>{language === 'English' ? 'Our Services' : 'Nos Services'}<br /></span>
          <h2>{language === 'English' ? 'Our Services' : 'Nos ServiceS'}</h2>
          <p>{language === 'English' ? 'We offer the following services below' : 'Nous offrons les services suivants ci-dessous'}</p>
        </div>
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div className="card">
                <div className="card-img">
                  <img src="assets/img/service-1.jpg" alt="" className="img-fluid" />
                </div>
                <h3>{language === 'English' ? 'Storage' : 'stockage'}</h3>
                <p>{language === 'English' ? 'At LANDO LOGISTICS offers secure and efficient solutions for your goods. Our warehouses are equipped with modern facilities, including temperature control systems and advanced security features, ensuring that your products are stored in optimal conditions. Whether you need short or long term storage, we offer flexible options to suit your needs.' : 'Chez LANDO LOGISTICS offre des solutions sécurisées et efficaces pour vos marchandises. Nos entrepôts sont équipés de installations modernes, y compris des systèmes de contrôle de la température et des dispositifs de sécurité avancés, garantissant que vos produits sont stockés dans des conditions optimales. Que vous ayez besoin d\'un stockage à court ou à long terme, nous proposons des options flexibles adaptées à vos besoins.'}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div className="card">
                <div className="card-img">
                  <img src="assets/img/service-2.jpg" alt="" className="img-fluid" />
                </div>
                <h3>{language === 'English' ? 'Sea Freight' : 'Fret Maritime'}</h3>
                <p>{language === 'English' ? 'Thanks to our expertise in international logistics, we ensure the secure movement of your goods across oceans, while guaranteeing optimized delivery times.' : 'Grâce à notre expertise en logistique internationale, nous assurons le déplacement sécurisé de vos marchandises à travers les océans, tout en garantissant des délais de livraison optimisés.'}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="card">
                <div className="card-img">
                  <img src="assets/img/service.jpeg" alt="" className="img-fluid" />
                </div>
                <h3>{language === 'English' ? 'Customs clearance' : 'Dedouanment'}</h3>
                <p>{language === 'English' ? 'Is an essential service offered by LANDO LOGISTICS to ensure the smooth movement of goods across international borders. We take care of all the necessary paperwork, duties and regulations to avoid delays. Our team of experts works closely with customs authorities to ensure your shipments meet all legal requirements.' : 'Est un service essentiel proposé par LANDO LOGISTICS pour assurer la bonne circulation des marchandises à travers les frontières internationales. Nous nous occupons de tous les documents, droits et réglementations nécessaires pour éviter les retards. Notre équipe d\'experts travaille en étroite collaboration avec les autorités douanières pour garantir que vos envois répondent à toutes les exigences légales.'}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div className="card">
                <div className="card-img">
                  <img src="assets/img/service-4.jpg" alt="" className="img-fluid" />
                </div>
                <h3>{language === 'English' ? 'Air Freight' : 'Fret Aerien'}</h3>
                <p>{language === 'English' ? 'We offer a wide range of LANDO LOGISTICS air freight services designed to provide maximum flexibility and efficiency for your shipping needs.' : 'Nous proposons une large gamme de services de fret aérien LANDO LOGISTICS conçus pour offrir une flexibilité et une efficacité maximales pour vos besoins d\'expédition'}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div className="card">
                <div className="card-img">
                  <img src="assets/img/service-5.jpeg" alt="" className="img-fluid" />
                </div>
                <h3>{language === 'English' ? 'Delivery' : 'Livraison'}</h3>
                <p>{language === 'English' ? 'Whether you are sending small parcels or large cargoes, our solutions are customized to ensure fast and reliable delivery of your goods.' : 'Que ce soit pour l\'envoi de petits colis ou de grandes cargaisons, nos solutions sont personnalisées pour garantir une livraison rapide et fiable de vos marchandises.'}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
              <div className="card">
                <div className="card-img">
                  <img src="assets/img/service-6.jpg" alt="" className="img-fluid" />
                </div>
                <h3>{language === 'English' ? 'Storage' : 'Entreposage'}</h3>
                <p>{language === 'English' ? 'Quas assumenda non occaecati molestiae. In aut earum sed natus eatae in vero. Ab modi quisquam aut nostrum unde et qui est non quo nulla' : 'Quas assumenda non occaecati molestiae. In aut earum sed natus eatae in vero. Ab modi quisquam aut nostrum unde et qui est non quo nulla'}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="call-to-action" className="call-to-action section dark-background">
        <img src="assets/img/cta-bg.jpg" alt="" />
        <div className="container">
          <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-xl-10">
              <div className="text-center">
                <h3>{language === 'English' ? 'CALL TO ACTION' : 'APPEL À L\'ACTION'}</h3>
                <p>{language === 'English' ? 'To learn more about Lando Global Logistics and how we operate, contact us or visit our office' : 'Pour en savoir plus sur Lando Global Logistics et notre fonctionnement, contactez-nous ou visitez notre bureau'}</p>
                <a className="cta-btn" href="#contact" style={{ textDecoration: "none", }}>{language === 'English' ? 'Contact' : 'Contact'}</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features section">
        <div className="container section-title" data-aos="fade-up">
          <span>{language === 'English' ? 'Features' : 'Caractéristiques'}</span>
          <h2>{language === 'English' ? 'Features' : 'Caractéristiques'}</h2>
          <p>{language === 'English' ? 'Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit' : 'Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit'}</p>
        </div>
        <div className="container">
          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-5 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
              <img src="assets/img/deliv.png" className="img-fluid" alt="" />
            </div>
            <div className="col-md-7" data-aos="fade-up" data-aos-delay="100">
              <h3>{language === 'English' ? 'DOOR TO DOOR DELIVERY' : 'LIVRAISON PORTE À PORTE'}</h3>
              <p className="fst-italic">{language === 'English' ? 'At LANDO LOGISTICS, we transport goods directly from the seller or manufacturer’s location to the customer’s home. This eliminates the need for customers to pick up their items from a central distribution center or collection point. We make it a convenient option for customers, providing them with a hassle-free experience as they receive their packages directly at their home or office.' : 'Chez LANDO LOGISTICS, nous transportons les marchandises directement du lieu du vendeur ou du fabricant jusqu\'au domicile du client. Nous éliminons ainsi la nécessité pour les clients de récupérer leurs articles dans un centre de distribution central ou un point de collecte. Nous en faisons une option pratique pour les clients, leur offrant une expérience sans tracas lorsqu\'ils reçoivent leurs colis directement chez eux ou à leur bureau'}
              </p>
              <ul>
                <li><i className="bi bi-check"></i><span>{language === 'English' ? 'Save time' : 'Gagnez du temps'}</span></li>
                <li><i className="bi bi-check"></i> <span>{language === 'English' ? 'Take advantage of credibility' : 'Profitez de la crédibilité'}</span></li>
                <li><i className="bi bi-check"></i> <span>{language === 'English' ? 'Enjoy the convenience' : 'Profitez de la commodité'}</span></li>
              </ul>
            </div>
          </div>
          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-5 order-1 order-md-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
              <img src="assets/img/maps.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 order-2 order-md-1" data-aos="fade-up" data-aos-delay="200">
              <h3>{language === 'English' ? 'TRACK YOUR GOODS IN REAL TIME' : 'STOCKEZ VOS MARCHANDISES EN TEMPS RÉEL'}</h3>
              <p className="fst-italic">
              {language === 'English' ? 'With LANDO LOGISTICS, you have the ability to manage your goods with real-time tracking and monitoring. This service allows you to stay up-to-date on your stock levels, ensuring that you always have accurate information. This allows you to optimize your operations, reduce the risk of overstocking or stockouts, and make informed decisions quickly.' : ' Avec LANDO LOGISTICS, vous avez la possibilité de gérer vos marchandises avec un suivi et une surveillance en temps réel. Ce service vous permet de rester à jour sur vos niveaux de stock, garantissant que vous disposez toujours d\'informations précises. Vous pouvez ainsi optimiser vos opérations, réduire le risque de surstockage ou de ruptures de stock, et prendre des décisions éclairées rapidement.'}
              </p>
            </div>
          </div>
          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-5 d-flex align-items-center" data-aos="zoom-out">
              <img src="assets/img/features-3.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-md-7" data-aos="fade-up">
              <h3>{language === 'English' ? 'SECURITY OF YOUR GOODS' : 'SÉCURITÉ DE VOS MARCHANDISES'}</h3>
              <p>{language === 'English' ? 'Cargo security is a top priority for our company. At LANDO LOGISTICS Logistics, we implement robust security measures to protect our clients’ goods throughout the transportation process. This includes careful handling and the use of secure vehicles. Our warehouses are equipped with surveillance devices and alarms to prevent unauthorized access. In addition, we train our staff to handle goods safely and efficiently, reducing the risk of damage or theft. With these measures in place, we offer our clients peace of mind, ensuring that their goods arrive at their destination safely.' : 'La sécurité des marchandises est une priorité absolue pour notre entreprise. Chez LANDO LOGISTICS, nous mettons en place des mesures de sécurité robustes pour protéger les biens de nos clients tout au long du processus de transport. Cela inclut une manipulation soigneuse et l\'utilisation de véhicules sécurisés. Nos entrepôts sont équipés de dispositifs de surveillance et d\'alarmes pour prévenir tout accès non autorisé. De plus, nous formons notre personnel à gérer les marchandises de manière sûre et efficace, réduisant ainsi les risques de dommages ou de vols. Avec ces mesures en place, nous offrons à nos clients une tranquillité d\'esprit, en assurant que leurs marchandises arrivent à destination en toute sécurité.'}</p>
              <ul>
                <li><i className="bi bi-check"></i> <span>{language === 'English' ? 'Safe handling of goods' : 'Manutention sécuritaire des marchandises'}</span></li>
                <li><i className="bi bi-check"></i><span>{language === 'English' ? '24 hour security' : 'Sécurité 24 heures sur 24'}</span></li>
                <li><i className="bi bi-check"></i> <span>{language === 'English' ? 'Secure storage facility' : 'Installation de stockage sécurisée'}</span></li>
              </ul>
            </div>
          </div>
          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-5 order-1 order-md-2 d-flex align-items-center" data-aos="zoom-out">
              <img src="assets/img/features-4.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 order-2 order-md-1" data-aos="fade-up">
              <h3>{language === 'English' ? 'TRANSPORT OF BULK GOODS' : 'TRANSPORT DE MARCHANDISES EN VRAC'}</h3>
              <p className="fst-italic">
              {language === 'English' ? 'With LANDO LOGISTICS, we transport goods in large and small quantities. At LANDO LOGISTICS, we ensure that these goods are handled with care and transported in optimal conditions to avoid loss or damage. With specialized equipment and logistics expertise, we guarantee efficient and secure transportation of bulk goods.' : 'Avec LANDO LOGISTICS, nous transportons des marchandises en grandes et petites quantités. Chez LANDO LOGISTICS, nous veillons à ce que ces marchandises soient manipulées avec soin et transportées dans des conditions optimales pour éviter les pertes ou les dommages. Grâce à un équipement spécialisé et à une expertise logistique, nous garantissons un transport efficace et sécurisé de marchandises en vrac.'}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="testimonials" className="testimonials section dark-background">
      </section>

      <div className="col-lg-8" id="contact" style={{margin: "auto", backgroundColor: "#103164", marginTop: "25px", marginBottom: "25px", borderRadius: "10px",color: "#fff", fontWeight: "bold", letterSpacing: "1px",}}>
        <form action="https://formsubmit.co/info@landologistics.com" method="POST" style={{padding: "30px", }}>
          <div className="mb-3">
            <label className="form-label">{language === 'English' ? 'Name' : 'Nom:'}</label>
            <input name="name" type="text" className="form-control" placeholder="Ecrire ton nom" style={{ padding: "15px", }} />
          </div>
          <div className="mb-3">
            <label className="form-label">{language === 'English' ? 'Contact' : 'Contact:'}</label>
            <input name="contact" type="text" className="form-control" placeholder="Ecrire ton Contact" style={{ padding: "15px", }} />
          </div>
          <div className="mb-3">
            <label>{language === 'English' ? 'Write your Message' : 'Ecrire ton message:'}</label>
            <textarea name="message" className='form-control'></textarea>
          </div>

          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_cc" value="landologisticsdev@gmail.com" />
          <input type="hidden" name="_template" value="table" />
          <button type="submit" class="btn btn-primary">{language === 'English' ? 'Submit' : 'Soumettre'}</button>
        </form>
      </div>

      <footer className="footer dark-background">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-about">
              <a href="index.html" className="logo d-flex align-items-center" style={{textDecoration: "none",}}>
                <span className="sitename">{language === 'English' ? 'Follow us' : 'Suivez-nous'}</span>
              </a>
              <p>{language === 'English' ? 'Follow us on our various social media platforms' : 'Suivez-nous sur nos différentes plateformes de médias sociaux'}</p>
              <div className="social-links d-flex mt-4">
                <a href="#"><i className="bi bi-twitter-x"></i></a>
                <a href="https://www.facebook.com/Lando.trans"><i className="bi bi-facebook"></i></a>
                <a href="https://www.instagram.com/lando_global_logistics/"><i className="bi bi-instagram"></i></a>
                <a href="#"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>{language === 'English' ? 'Our services' : 'Nos service'}</h4>
              <ul>
                <li>{language === 'English' ? 'AIR FREIGHT' : 'FRET AERIEN'}</li>
                <li>{language === 'English' ? 'SEA FREIGHT' : 'FRET MARITIME'}</li>
                <li>{language === 'English' ? 'TRANSPORTATION' : 'TRANSPORT'}</li>
                <li>{language === 'English' ? 'CUSTOMS CLEARANCE' : 'DEDOUANMENT'}</li>
                <li>{language === 'English' ? 'DELIVERY' : 'LIVRAISON'}</li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>{language === 'English' ? 'Contact Us' : 'Contactez-nous'}</h4>
              <p>{language === 'English' ? 'Hamdallaye' : 'Hamdallaye'}</p>
              <p>{language === 'English' ? 'Ratoma Conakry' : 'Ratoma Conakry'}</p>
              <p>{language === 'English' ? 'Guinea' : 'Guinea'}</p>
              <p className="mt-4"><strong>{language === 'English' ? 'Phone' : 'Phone : '}</strong> <span>+224 626 88 71 62</span></p>
              <p><strong>{language === 'English' ? 'Email' : 'Email : '}</strong> <span>info@landologistics.com</span></p>
            </div>
          </div>
        </div>
        <a  class="whats-app" href="https://wa.me/224626887162?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services." target="_blank" rel="noopener noreferrer">
            <i class="fab fa-whatsapp my-float"></i>
        </a>

        <div className="container copyright text-center mt-4">
          <p> &copy; {currentYear} Lando Logistics Services All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default NavBar;