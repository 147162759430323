import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    TextField, Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import ImageModal from '../modals/ImageModal';
import UploadModal from '../modals/UploadModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

function EmployeesTable() {

    const [employeeList, setEmployeeList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const initialFilters = {
        employeeName: '',
        employeeAddress: '', // Add startDate and endDate fields
        employeeDepartment: '', // Add startDate and endDate fields
        employeeContact: '',
    };

    const navigate = useNavigate(); // useNavigate hook

    const [modalEmployeeId, setModalEmployeeId] = useState(null); // Store the goodsID for the modal
    const [modalEmployeeText, setModalEmployeeText] = useState(null); // Store the goodsID for the modal

    const [showFilters, setShowFilters] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for upload modal


    const handleImageClick = (imageUrl, rowId) => {
        setSelectedImage(imageUrl);
        setSelectedRowId(rowId);
        console.log(`rowId ${rowId}`);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setSelectedRowId(null);
    };


    const [filters, setFilters] = useState(initialFilters);

    const [isEditing, setIsEditing] = useState(false);
    const [editedEmployee, setEditedEmployee] = useState({});

    const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);

    const toggleEdit = (employeeId) => {
        setCurrentlyEditedRow(employeeId === currentlyEditedRow ? null : employeeId);
        setEditedEmployee({ ...editedEmployee, [employeeId]: {} });
    };


    // Function to cancel the edit and revert to the original values
    const cancelEdit = (rowId) => {
        // Remove the row from the editedGoods state
        const newEditedEmployee = { ...editedEmployee };
        delete newEditedEmployee[rowId];
        setEditedEmployee(newEditedEmployee);

        // Reset the currentlyEditedRow state
        setCurrentlyEditedRow(null);
    };

    const handleDateChange = (employeeId, field, date) => {
        setEditedEmployee((prevEditedEmployee) => ({
            ...prevEditedEmployee,
            [employeeId]: {
                ...prevEditedEmployee[employeeId],
                [field]: date,
            },
        }));
    };

    const testID = (employeeID) => {
        console.log('employeeID');
        console.log(employeeID);
    };
    const handleUploadButtonClick = (employeeID, targetImageTitle) => {
        setModalEmployeeId(employeeID); // Set the employeeID for the modal
        setModalEmployeeText(targetImageTitle); // Set the target image title for the modal
        setIsUploadModalOpen(true); // Open the upload modal
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false); // Close the upload modal
        fetchData(); // Refresh the data to display the uploaded image
    };

    const handleEditChange = (employeeId, field, value) => {
        setEditedEmployee((prevEditedEmployee) => ({
            ...prevEditedEmployee,
            [employeeId]: {
                ...prevEditedEmployee[employeeId],
                [field]: value,
            },
        }));
    };



    const saveEditedEmployee = async (employeeId) => {
        const authToken = localStorage.getItem('token');
        const editedData = editedEmployee[employeeId];
    
        console.log(`editedData ${JSON.stringify(editedData)}`);
        console.log(`employeeId ${JSON.stringify(employeeId)}`);
    
        try {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}/employees/update/${employeeId}`, 
                {
                    ...editedData,
                    employeeId: employeeId, // Ensure employeeId is included in the request
                }, 
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
    
            // Show success message
            toast.success('Employee updated successfully', {
                position: 'top-center',
                autoClose: 3000,
            });
    
            // Refresh the data
            fetchData();
    
            // Toggle off editing mode
            toggleEdit();
    
        } catch (error) {
            console.error('Error updating employee:', error);
            
            // Show error message to user
            toast.error('Failed to update employee. Please try again.', {
                position: 'top-center',
                autoClose: 5000,
            });
        }
    };




    // Function to reset filters to their initial state
    const handleResetFilters = () => {
        setFilters(initialFilters);
    };


    const [updateTable, setUpdateTable] = useState(0)


    
   


    const fetchData = async () => {
        try {
            const authToken = localStorage.getItem('token');
    
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/employees/getAll`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
    
            console.log(`response.data ${JSON.stringify(response.data)}`);
    
            if (response.status === 200) {
                const formattedEmployeeList = response.data.map((employee) => ({
                    _id: employee._id,
                    employeeName: employee.employeeName || '',
                    employeeAddress: employee.employeeAddress || '',
                    employeeContact: employee.employeeContact || '',
                    employeeNationality: employee.employeeNationality || '',
                    employeeGender: employee.employeeGender || '',
                    employeeDepartment: employee.employeeDepartment || 0,
                    employeePosition: employee.employeePosition || 0,
                    employeeAsset: employee.employeeAsset || '',
                    employeeSalary: employee.employeeSalary || 0,
                    employeePaymentStatus: employee.employeePaymentStatus || 0,
                    employeeEmail: employee.employeeEmail || '',
                    emergencyContactName: employee.emergencyContactName || '',
                    emergencyContactPhone: employee.emergencyContactPhone || '',
                    employmentStatus: employee.employmentStatus || '',
                    contractStartDate: employee.contractStartDate || '',
                    contractEndDate: employee.contractEndDate || '',
                    employeeNationalIdImages: employee.employeeNationalIdImages || [],
                    emergencyContactName: employee.emergencyContactName || '',
                    emergencyContactPhone: employee.emergencyContactPhone || '',

                }));
    
                // Sort the formattedEmployeeList based on employeeCreationdate
                if (formattedEmployeeList[0] && formattedEmployeeList[0].employeeCreationdate) {
                    formattedEmployeeList.sort((a, b) => new Date(b.employeeCreationdate) - new Date(a.employeeCreationdate));
                }
    
                setEmployeeList(formattedEmployeeList);
                console.log(`employeeList ${JSON.stringify(formattedEmployeeList)}`);
                setIsLoading(false);
            } else {
                console.error('Error fetching employees:', response.statusText);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching employees:', error);
            setIsLoading(false);
            // Handle error and redirection here
        }
    };

    useEffect(() => {
        fetchData();
    }, [updateTable]);

    const formatDate = (date) => {
        // Format a Date object to 'YYYY-MM-DD'
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };




    const handleDeleteEmployee = async (employeeId) => {
        try {
            const accessToken = localStorage.getItem('token');
            if (!accessToken) {
                throw new Error('No access token found in localStorage');
            }

            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/employees/delete/${employeeId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {

                // Reload the current page after a successful delete
                window.location.reload();
                // Remove the deleted item from the goodsList
                setEmployeeList((prevEmployeeList) => prevEmployeeList.filter((employee) => employee.id !== employeeId));
            } else {
                console.error('Failed to delete employee:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting employee:', error);
        }
    };

    

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
    };

   

    const filteredEmployee = employeeList.filter((employee) => {
        const {
            _id,
            employeeName,
            employeeAddress,
            employeeDepartment,
            employeeContact,
            // countryDestination,
        } = filters;

        const employeeDate = new Date(employee.employeeCreationdate);

        const idFilterMatch = !_id || employee._id.toString().includes(_id.toString());

        return (
            idFilterMatch &&
            (employeeName === '' || employee.employeeName === employeeName) &&
            (employeeAddress === '' || employee.employeeAddress === employeeAddress) &&
            (employeeDepartment === '' || employee.employeeDepartment === employeeDepartment) &&
            (employeeContact === '' || employee.employeeContact === employeeContact)
        );
    });


    if (isLoading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        );
    }



    const generateExcelData = () => {
        const data = [];
        data.push([
            'Employee ID',
            'Employee Name',
            'Employee Email',
            'Employee Contact',
            'Employee Address',
            'Employee Nationality',
            'Employee Gender',
            'Employee Department',
            'Employee Position',
            'Employee Asset',
            'Employee Paid Amount',
            'Employee Payment Status',
            'Contract Start Date',
            'Contract End Date',
            'Emergency Contact Name',
            'Emergency Contact Phone',
            'ID Card',
        ]);

        filteredEmployee.forEach((employee) => {
            data.push([
                employee._id.slice(-6), // Extract the last 6 digits of the ID
                employee.employeeName,
                employee.employeeEmail,
                employee.employeeContact,
                employee.employeeAddress,
                employee.employeeNationality,
                employee.employeeGender,
                employee.employeeDepartment,
                employee.employeePosition,
                employee.employeeAsset,
                employee.employeeSalary,
                employee.employmentStatus,
                employee.contractStartDate,
                employee.contractEndDate,
                employee.emergencyContactName,
                employee.emergencyContactPhone,
                employee.employeeImagesLinks.join(', '),
            ]);
        });

        return data;
    };

    const handleDownloadExcel = () => {
        const data = generateExcelData();
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
        XLSX.writeFile(wb, 'expenses.xlsx');
    
    };



    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };
   
    const handleGoToNewEmployeeForm = () => {
        navigate('/employeeForm')
    }

    function formatCurrency(amount) {
        return amount.toLocaleString('en-US', {
            style: 'decimal',
            // currency: 'GNF',
            minimumFractionDigits: 2,
        });
    }


    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };
  return (
    <div style={{ margin: '0 auto', maxWidth: '1600px' }}>

    <Typography variant="h2" style={{ 
        fontSize: '3.5rem', 
        fontWeight: 'bold', 
        margin: '12px auto',
        textAlign: 'center',
    }}
        >

        Employees
    </Typography>

    <br />
    <Button
        variant="contained"
        onClick={toggleFilters}
        style={{
            backgroundColor: 'blue',
            color: 'white',
            minWidth: '120px',
            marginBottom: '10px',
        }}
    >
        {showFilters ? 'Hide Filters' : 'Show Filters'}
    </Button>


    {showFilters && (
        <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>


            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                {/* <InputLabel>Goods ID</InputLabel> */}
                <TextField
                    label="Emloyee ID"
                    name="_id"
                    value={filters._id}
                    onChange={handleFilterChange}
                />
            </FormControl>


            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                <TextField
                    label="Employee Name"

                    name="employeeName"
                    value={filters.employeeName}
                    onChange={handleFilterChange}
                />
            </FormControl>

            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                <TextField
                    label="Employee Address"

                    name="employeeAddress"
                    value={filters.employeeAddress}
                    onChange={handleFilterChange}
                />
            </FormControl>

            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                {/* <InputLabel>forwarder Name</InputLabel> */}
                <TextField
                    label='Employee Contact'
                    name="employeeContact"
                    value={filters.employeeContact}
                    onChange={handleFilterChange}
                />
            </FormControl>

            {/* <FormControl style={{ minWidth: '150px', flex: '1' }}>
                <TextField
                    label='Nationality'

                    name="forwarderTel"
                    value={filters.forwarderTel}
                    onChange={handleFilterChange}
                />


            </FormControl> */}

            <FormControl style={{ minWidth: '150px', flex: '1' }}>
                <TextField
                    label='Department'

                    name="employeeDepartment"
                    value={filters.employeeDepartment}
                    onChange={handleFilterChange}
                />


            </FormControl> 

            <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>
                <Button
                    variant="contained"
                    style={{ backgroundColor: 'darkred', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}
                    onClick={handleResetFilters}
                >
                    Reset Filters
                </Button>

                <Button
                    variant="contained"
                    onClick={handleDownloadExcel}
                    style={{ backgroundColor: 'primary', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}

                >
                    Download
                </Button>

                <Button
                    variant="contained"
                    // onClick={}
                    style={{ backgroundColor: 'green', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}

                >
                    Upload
                </Button>

            </div>




        </div>

    )} <br />
    <br />

   <Button 
        variant='contained' 
        color='secondary'
        onClick={handleGoToNewEmployeeForm}
    >Add Employee</Button> <br />
    <br />
<div> <h3>Total {filteredEmployee.length} </h3> </div>

    {/* Upload modal */}
    {isUploadModalOpen && (
        <UploadModal employeeID={modalEmployeeId}
            setUpdateTables={ setUpdateTable }

        modalEmployeeText={modalEmployeeText} onClose={handleUploadModalClose} />
    )}
    {selectedImage && (
        <ImageModal
        style={{
                zIndex: 9999,

        }}
        imageUrl={selectedImage} 
            employeeID={ selectedRowId }
            setUpdateTables={ setUpdateTable}
        
        onClose={handleCloseModal} />
    )}
<TableContainer 
style={{
    height: '750px'
}}
component={Paper}>
    <Table>
            <TableHead
                style={{
                    backgroundColor: '#78AEF9',
                    position: 'sticky',
                    top: 0,
                    zIndex: 99,
                    fontWeight: 'bold', // Apply bold font
                }}
            >
            <TableRow>

                    <TableCell 
                    style={{
                    fontSize: '0.96rem',
                    fontFamily: 'monospace',
                    fontWeight: 'bold',
                    }}
                >Employee ID</TableCell>

                    <TableCell
                        style={ {
                            fontSize: '0.96rem',
                            fontFamily: 'monospace',
                            fontWeight: 'bold',
                        } }
                    >Employee Name</TableCell>

                <TableCell 
                    style={{
                    fontSize: '0.96rem',
                    fontFamily: 'monospace',
                    fontWeight: 'bold',
                    }}
                >Employee Email</TableCell>
             


                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Employee Contact</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Employee Address</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Nationality</TableCell>

                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Gender</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Department</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Position</TableCell>

                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Asset No</TableCell>

               <TableCell 
                style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
               >Salary</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Status</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Start Date</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >End Date</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Emmergency Contact Name</TableCell>
                <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >Emmergency Contact Phone</TableCell>

                    <TableCell
                        style={ {
                            fontSize: '0.96rem',
                            fontFamily: 'monospace',
                            fontWeight: 'bold',
                        } }
                    > ID Card</TableCell>


                {/* <TableCell 
                 style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                >HR Comment</TableCell> */}

                    <TableCell 
                     style={{
        fontSize: '0.96rem',
        fontFamily: 'monospace',
        fontWeight: 'bold',
    }}
                    > Edit</TableCell>

            </TableRow>
        </TableHead>
        <TableBody>
              
              

                {filteredEmployee.map((employee,index) => (
                  

                        <TableRow
                            key={employee._id}
                            style={{
                                backgroundColor: index % 2 === 0 ? '#ADD8E6' : '#73c3e6', // Use the color codes
                            }}
                        >
                        <TableCell>{ index + 1 }</TableCell>
                        {/* <TableCell>{ goods.goodsCreationdate }</TableCell>  */}


                        <TableCell
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.employeeName || employee.employeeName}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'employeeName', e.target.value)
                                    }
                                />
                            ) : (
                                employee.employeeName
                            )}
                        </TableCell>



                        <TableCell
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.employeeEmail || employee.employeeEmail}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'employeeEmail', e.target.value)
                                    }
                                />
                            ) : (
                                employee.employeeEmail
                            )}
                        </TableCell>
                      


                        <TableCell>
                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.employeeContact ||  employee.employeeContact}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'employeeContact', e.target.value)
                                    }
                                />
                            ) : (
                                    employee.employeeContact
                            )}
                        </TableCell>





                        <TableCell
                        
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.employeeAddress || employee.employeeAddress}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'employeeAddress', e.target.value)
                                    }
                                />
                            ) : (
                                employee.employeeAddress
                            )}
                        </TableCell>



                        <TableCell 
                        
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}
                            
                            >
                            {currentlyEditedRow === employee._id ? (
                                <FormControl>
                                    <InputLabel>Nationality</InputLabel>
                                    <Select
                                        value={editedEmployee[employee._id]?.employeeNationality || employee.employeeNationality}
                                        onChange={(e) =>
                                            handleEditChange(employee._id, 'employeeNationality', e.target.value)
                                        }
                                    >
                                        <MenuItem value="Guinean">Guinean</MenuItem>
                                        <MenuItem value="Sierra Leonean">Sierra Leonean</MenuItem>
                                        <MenuItem value="Ivorian">Ivory Coast</MenuItem>
                                        <MenuItem value="Malian">Mali</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                employee.employeeNationality
                            )}
                        </TableCell>


                        <TableCell
                        
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <FormControl>
                                    <InputLabel>Gender</InputLabel>
                                    <Select
                                        value={editedEmployee[employee._id]?.employeeGender || employee.employeeGender}
                                        onChange={(e) =>
                                            handleEditChange(employee._id, 'employeeGender', e.target.value)
                                        }
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        {/* <MenuItem value="Ivorian">Ivory Coast</MenuItem>
                                        <MenuItem value="Malian">Mali</MenuItem> */}
                                    </Select>
                                </FormControl>
                            ) : (
                                employee.employeeGender
                            )}
                        </TableCell>


                        <TableCell>
                            {currentlyEditedRow === employee._id ? (
                                <FormControl>
                                    <InputLabel>Department</InputLabel>
                                    <Select
                                        value={editedEmployee[employee._id]?.employeeDepartment || employee.employeeDepartment}
                                        onChange={(e) =>
                                            handleEditChange(employee._id, 'employeeDepartment', e.target.value)
                                        }
                                    >
                                        <MenuItem value="IT">IT</MenuItem>
                                        <MenuItem value="Commercial">Commercial</MenuItem>
                                        <MenuItem value="Communication">Communication</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                employee.employeeDepartment
                            )}
                        </TableCell>

                      

                        <TableCell 
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.employeePosition || employee.employeePosition}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'employeePosition', e.target.value)
                                    }
                                />
                            ) : (
                                employee.employeePosition
                            )}
                        </TableCell>

                        <TableCell 
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.employeeAsset || employee.employeeAsset}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'employeeAsset', e.target.value)
                                    }
                                />
                            ) : (
                                employee.employeeAsset
                            )}
                        </TableCell>

                        <TableCell 
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.employeeSalary || employee.employeeSalary}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'employeeSalary', e.target.value)
                                    }
                                />
                            ) : (
                                employee.employeeSalary
                            )}
                        </TableCell>
                        <TableCell>
                            {currentlyEditedRow === employee._id ? (
                                <FormControl>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={editedEmployee[employee._id]?.employmentStatus || employee.employmentStatus}
                                        onChange={(e) =>
                                            handleEditChange(employee._id, 'employmentStatus', e.target.value)
                                        }
                                    >
                                        <MenuItem value="active">Active</MenuItem>
                                        <MenuItem value="probation">Probation</MenuItem>
                                        <MenuItem value="suspended">Suspended</MenuItem>
                                        <MenuItem value="terminated">Terminated</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                employee.employmentStatus
                            )}
                        </TableCell>

                        <TableCell 
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}
                            
                            >
                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.contractStartDate || employee.contractStartDate}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'contractStartDate', e.target.value)
                                    }
                                />
                            ) : (
                                    formatDateToFrench(employee.contractStartDate)
                            )}
                        </TableCell>
                        <TableCell 
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}
                            
                            >
                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.contractEndDate || employee.contractEndtDate}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'contractEndDate', e.target.value)
                                    }
                                />
                            ) : (
                                    formatDateToFrench(employee.contractEndDate)
                            )}
                        </TableCell>
                        <TableCell 
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.emergencyContactName || employee.emergencyContactName}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'emergencyContactName', e.target.value)
                                    }
                                />
                            ) : (
                                employee.emergencyContactName
                            )}
                        </TableCell>

                        <TableCell 
                            style={{
                                whiteSpace: 'nowrap', // Prevent content from wrapping
                                overflow: 'hidden',   // Hide overflowing content
                                textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                minWidth: '100px',   // Set a minimum width for the cell
                                width: 'auto'
                            }}>

                            {currentlyEditedRow === employee._id ? (
                                <TextField
                                    value={editedEmployee[employee._id]?.emergencyContactPhone || employee.emergencyContactPhone}
                                    onChange={(e) =>
                                        handleEditChange(employee._id, 'emergencyContactPhone', e.target.value)
                                    }
                                />
                            ) : (
                                employee.emergencyContactPhone
                            )}
                        </TableCell>

                        <TableCell>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleUploadButtonClick(employee._id, 'employeeNationalIdImages')}
                                    style={{
                                        borderRadius: '50%',  // Make the button round
                                        width: '30px',        // Set the button width
                                        height: '30px',       // Set the button height
                                        padding: '0',         // Remove padding
                                        minWidth: 'unset',    // Remove minWidth
                                    }}
                                >
                                    +
                                </Button>
                                {employee.employeeNationalIdImages && employee.employeeNationalIdImages.map((imageLink, index) => (
                                    <img
                                        key={index}
                                        src={imageLink}
                                        alt={`Image ${index}`}
                                        style={{
                                            display: 'inline-block',
                                            maxWidth: '43px',
                                            maxHeight: '43px',
                                            marginRight: '5px',
                                            borderRadius: '50px', // Set your desired border radius
                                            marginTop: '0',      // Remove marginTop
                                            marginBottom: '0'    // Remove marginBottom
                                        }}
                                        onClick={() => handleImageClick(imageLink, employee._id)}
                                    />
                                ))}
                            </div>
                        </TableCell>

                        <TableCell>
                            {currentlyEditedRow === employee._id ? (

                                <>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => saveEditedEmployee(employee._id)}
                                >
                                    Save
                                </Button>


                                    <Button
                                        variant="contained"
                                        onClick={() => cancelEdit(employee._id)}
                                        style={{ backgroundColor: 'darkred', color: 'white' , marginTop: '5px' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ backgroundColor: 'red' }}
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this employee?')) {
                                                handleDeleteEmployee(employee._id);
                                            }
                                        }}
                                    >
                                        Delete
                                    </Button>

                                </>
                                

                                
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => toggleEdit(employee._id)}
                                >
                                    Edit
                                </Button>
                            )}
                        </TableCell>

                     
                    </TableRow>
                ))}
              
        </TableBody>
        
    </Table>
      
</TableContainer>

</div>
  )
}

export default EmployeesTable