import React, { useState,useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select, Typography,
    MenuItem,
    Box, Stack, CircularProgress
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import ListIcon from '@mui/icons-material/List';
import Fab from '@mui/material/Fab';


import Dropzone from 'react-dropzone';
// import { Image, Video } from 'cloudinary-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialFormData = {
    employeeName: '',
    janPayment: '',
    febPayment: '',
    marPayment: '',
    aprPayment: '',
    mayPayment: '',
    junPayment: '',
    julPayment: '',
    augPayment: '',
    sepPayment: '',
    octPayment: '',
    novPayment: '',
    decPayment: '',
    paymentReceipt: [],
}
const SalaryForm = () => {
  return (
    <div>
      
    </div>
  )
}

export default SalaryForm
