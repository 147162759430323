import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    TextField, Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import ImageModal from '../modals/ImageModal';
import UploadModal from '../modals/UploadModal';


function SalaryTable() {
  const [employeeList, setEmployeeList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const initialFilters = {
        employeeName: '',
        employeeAddress: '', // Add startDate and endDate fields
        employeeDepartment: '', // Add startDate and endDate fields
        employeeContact: '',
    };

    const navigate = useNavigate(); // useNavigate hook

    const [modalEmployeeId, setModalEmployeeId] = useState(null); // Store the goodsID for the modal
    const [modalEmployeeText, setModalEmployeeText] = useState(null); // Store the goodsID for the modal

    const [showFilters, setShowFilters] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for upload modal


    const handleImageClick = (imageUrl, rowId) => {
        setSelectedImage(imageUrl);
        setSelectedRowId(rowId);
        console.log(`rowId ${rowId}`);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setSelectedRowId(null);
    };


    const [filters, setFilters] = useState(initialFilters);

    const [isEditing, setIsEditing] = useState(false);
    const [editedEmployee, setEditedEmployee] = useState({});

    const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);

    const toggleEdit = (employeeId) => {
        setCurrentlyEditedRow(employeeId === currentlyEditedRow ? null : employeeId);
        setEditedEmployee({ ...editedEmployee, [employeeId]: {} });
    };


    // Function to cancel the edit and revert to the original values
    const cancelEdit = (rowId) => {
        // Remove the row from the editedGoods state
        const newEditedEmployee = { ...editedEmployee };
        delete newEditedEmployee[rowId];
        setEditedEmployee(newEditedEmployee);

        // Reset the currentlyEditedRow state
        setCurrentlyEditedRow(null);
    };

    const handleDateChange = (employeeId, field, date) => {
        setEditedEmployee((prevEditedEmployee) => ({
            ...prevEditedEmployee,
            [employeeId]: {
                ...prevEditedEmployee[employeeId],
                [field]: date,
            },
        }));
    };

    const testID = (employeeID) => {
        console.log('employeeID');
        console.log(employeeID);
    };
    const handleUploadButtonClick = (employeeID,targetImageTitle) => {
        setModalEmployeeId(employeeID); // Set the goodsID for the modal
        setModalEmployeeText(targetImageTitle); //
        setIsUploadModalOpen(true); // Open the upload modal
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false); // Close the upload modal
    };

    const handleEditChange = (employeeId, field, value) => {
        setEditedEmployee((prevEditedEmployee) => ({
            ...prevEditedEmployee,
            [employeeId]: {
                ...prevEditedEmployee[employeeId],
                [field]: value,
            },
        }));
    };



    const saveEditedEmployee = async (employeeId) => {
        const authToken = localStorage.getItem('token');
        const editedData = editedEmployee[employeeId];

        console.log(`editedData ${JSON.stringify(editedData)}`);
        console.log(`employeeId ${JSON.stringify(employeeId)}`);

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/goods-tracking/updateByAdmin/${employeeId}`, {  //to be change to employee id
                ...editedData,
                employeeTrackingID: employeeId, // Ensure employeeTrackingID is included in the request
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            fetchData();

            // Toggle off editing mode
            toggleEdit();

            // No need to update local state as we are directly updating the edited document
        } catch (error) {
            console.error('Error updating goods:', error);
        }
    };




    // Function to reset filters to their initial state
    const handleResetFilters = () => {
        setFilters(initialFilters);
    };


    const [updateTable, setUpdateTable] = useState(0)


    
   


    const fetchData = async () => {
        try {
            const authToken = localStorage.getItem('token');

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/goods-trackin/displayAll`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            console.log(`response.data ${JSON.stringify(response.data)}`);

            if (response.status === 200) {
                const formattedEmployeeList = response.data.map((employee) => ({
                    _id: employee._id,
                    employeeName: employee.employeeName || '',
                    employeeAddress: employee.employeeAddress || '',
                    employeeContact: employee.employeeContact || '',
                    employeeNationality: employee.employeeNationality || '',
                    employeeGender: employee.employeeGender || '',
                    employeeDepartment: employee.employeeDepartment || 0,
                    employeePosition: employee.employeePosition || 0,
                    employeePaidAmount: employee.employeePaidAmount || 0,
                    employeePaymentStatus: employee.employeePaymentStatus || 0,
                    employeeImagesLinks: employee.employeeImagesLinks || [],
                    employeeRemarks: employee.employeeRemarks || '',
                    employeeComment: employee.employeeComment || '',

                    // clientName: goods.clientName || '',
                }));

                // Sort the formattedGoodsList based on goodsCreationdate
                formattedEmployeeList.sort((a, b) => new Date(b.employeeCreationdate) - new Date(a.employeeCreationdate));

                setEmployeeList(formattedEmployeeList);

                console.log(`employeeList ${employeeList}`);
                setIsLoading(false);
            } else {
                console.error('Error fetching goods:', response.statusText);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching employee:', error);
            setIsLoading(false);
            // Handle error and redirection here
        }
    };

    useEffect(() => {
        fetchData();
    }, [updateTable]);

    const formatDate = (date) => {
        // Format a Date object to 'YYYY-MM-DD'
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };




    const handleDeleteEmployee = async (employeeId) => {
        try {
            const accessToken = localStorage.getItem('token');
            if (!accessToken) {
                throw new Error('No access token found in localStorage');
            }

            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/goods-tracking/delet/${employeeId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {

                // Reload the current page after a successful delete
                window.location.reload();
                // Remove the deleted item from the goodsList
                setEmployeeList((prevEmployeeList) => prevEmployeeList.filter((employee) => employee.id !== employeeId));
            } else {
                console.error('Failed to delete employee:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting employee:', error);
        }
    };

    

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
    };

   

    const filteredEmployee = employeeList.filter((employee) => {
        const {
            _id,
            employeeName,
            employeeAddress,
            employeeDepartment,
            employeeContact,
            // countryDestination,
        } = filters;

        const employeeDate = new Date(employee.employeeCreationdate);

        const idFilterMatch = !_id || employee._id.toString().includes(_id.toString());

        return (
            idFilterMatch &&
            (employeeName === '' || employee.employeeName === employeeName) &&
            (employeeAddress === '' || employee.employeeAddress === employeeAddress) &&
            (employeeDepartment === '' || employee.employeeDepartment === employeeDepartment) &&
            (employeeContact === '' || employee.employeeContact === employeeContact)
        );
    });


    if (isLoading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        );
    }



    const generateExcelData = () => {
        const data = [];
        data.push([
            'Employee ID',
            'Employee Name',
            'Employee Address',
            'Employee Contact',
            'Employee Nationality',
            'Employee Gender',
            'Employee Department',
            'Employee Position',
            'Employee Paid Amount',
            'Employee Payment Status',
            'Employee Receipt',
            'Employee Remarks',
            'Employee Comment',
        ]);

        filteredEmployee.forEach((employee) => {
            data.push([
                employee._id.slice(-6), // Extract the last 6 digits of the ID
                employee.employeeName,
                employee.employeeaddress,
                employee.employeeContact,
                employee.employeeNationality,
                employee.employeeGender,
                employee.employeeDepartment,
                employee.employeePosition,
                employee.employeePaidAmount,
                employee.employeePaymentStatus,
                employee.employeeImagesLinks.join(', '),
                employee.employeeComment,
                employee.employeeRemarks,
            ]);
        });

        return data;
    };

    const handleDownloadExcel = () => {
        const data = generateExcelData();
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Expenses');
        XLSX.writeFile(wb, 'expenses.xlsx');
    
    };



    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };
   
    const handleGoToNewEmployeeForm = () => {
        navigate('/employeeForm')
    }

    function formatCurrency(amount) {
        return amount.toLocaleString('en-US', {
            style: 'decimal',
            // currency: 'GNF',
            minimumFractionDigits: 2,
        });
    }


    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };
    return (
      <div style={{ margin: '0 auto', maxWidth: '1600px' }}>
  
      <Typography variant="h2" style={{ 
          fontSize: '3.5rem', 
          fontWeight: 'bold', 
          margin: '12px auto',
          textAlign: 'center',
      }}
          >
  
          Salaries
      </Typography>
  
      <br />
      <Button
          variant="contained"
          onClick={toggleFilters}
          style={{
              backgroundColor: 'blue',
              color: 'white',
              minWidth: '120px',
              marginBottom: '10px',
          }}
      >
          {showFilters ? 'Hide Filters' : 'Show Filters'}
      </Button>
  
  
      {showFilters && (
          <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>
  
  
              {/* <FormControl style={{ minWidth: '150px', flex: '1' }}>
                  <TextField
                      label="Emloyee ID"
                      name="_id"
                      value={filters._id}
                      onChange={handleFilterChange}
                  />
              </FormControl> */}
  
  
              <FormControl style={{ minWidth: '150px', flex: '1' }}>
                  <TextField
                      label="Employee Name"
  
                      name="employeeName"
                      value={filters.employeeName}
                      onChange={handleFilterChange}
                  />
              </FormControl>
  
              <FormControl style={{ minWidth: '150px', flex: '1' }}>
                  <TextField
                      label="Payment Status"
  
                      name="employeeAddress"
                      value={filters.employeeAddress}
                      onChange={handleFilterChange}
                  />
              </FormControl>
  
              <FormControl style={{ minWidth: '150px', flex: '1' }}>
                  {/* <InputLabel>forwarder Name</InputLabel> */}
                  <TextField
                      label='Month Paid'
                      name="employeeContact"
                      value={filters.employeeContact}
                      onChange={handleFilterChange}
                  />
              </FormControl>
  
              {/* <FormControl style={{ minWidth: '150px', flex: '1' }}>
                  <TextField
                      label='Nationality'
  
                      name="forwarderTel"
                      value={filters.forwarderTel}
                      onChange={handleFilterChange}
                  />
  
  
              </FormControl> */}
  
              {/* <FormControl style={{ minWidth: '150px', flex: '1' }}>
                  <TextField
                      label='Department'
  
                      name="employeeDepartment"
                      value={filters.employeeDepartment}
                      onChange={handleFilterChange}
                  />
  
  
              </FormControl>  */}
  
              <div style={{ marginBottom: '16px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '16px' }}>
                  <Button
                      variant="contained"
                      style={{ backgroundColor: 'darkred', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}
                      onClick={handleResetFilters}
                  >
                      Reset Filters
                  </Button>
  
                  <Button
                      variant="contained"
                      onClick={handleDownloadExcel}
                      style={{ backgroundColor: 'primary', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}
  
                  >
                      Download
                  </Button>
  
                  <Button
                      variant="contained"
                      // onClick={}
                      style={{ backgroundColor: 'green', color: 'white', flex: '1', minWidth: '150px', marginTop: '16px' }}
  
                  >
                      Upload
                  </Button>
  
              </div>
  
  
  
  
          </div>
  
      )} <br />
      <br />
  
     <Button 
          variant='contained' 
          color='secondary'
          //onClick={handleGoToNewEmployeeForm}
      >Add Salary</Button> <br />
      <br />
  <div> <h3>Total {filteredEmployee.length} </h3> </div>
  
      {/* Upload modal */}
      {isUploadModalOpen && (
          <UploadModal employeeID={modalEmployeeId}
              setUpdateTables={ setUpdateTable }
  
          modalEmployeeText={modalEmployeeText} onClose={handleUploadModalClose} />
      )}
      {selectedImage && (
          <ImageModal
          style={{
                  zIndex: 9999,
  
          }}
          imageUrl={selectedImage} 
              employeeID={ selectedRowId }
              setUpdateTables={ setUpdateTable}
          
          onClose={handleCloseModal} />
      )}
  <TableContainer 
  style={{
      height: '750px'
  }}
  component={Paper}>
      <Table>
              <TableHead
                  style={{
                      backgroundColor: '#78AEF9',
                      position: 'sticky',
                      top: 0,
                      zIndex: 99,
                      fontWeight: 'bold', // Apply bold font
                  }}
              >
              <TableRow>
  
                      <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Employee ID</TableCell>
  
                      <TableCell
                          style={ {
                              fontSize: '0.96rem',
                              fontFamily: 'monospace',
                              fontWeight: 'bold',
                          } }
                      >Employee Name</TableCell>
  
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Jan.</TableCell>
               
  
  
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Feb.</TableCell>
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Mar.</TableCell>
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Apr.</TableCell>
  
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >May</TableCell>
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Jun.</TableCell>
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Jul.</TableCell>
                 <TableCell 
                  style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                 >Aug.</TableCell>
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Sep.</TableCell>
  
                      <TableCell
                          style={ {
                              fontSize: '0.96rem',
                              fontFamily: 'monospace',
                              fontWeight: 'bold',
                          } }
                      >Oct.</TableCell>
  
  
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Nov.</TableCell>
                  <TableCell 
                   style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                  >Dec.</TableCell>
  
                      <TableCell 
                       style={{
          fontSize: '0.96rem',
          fontFamily: 'monospace',
          fontWeight: 'bold',
      }}
                      > Edit</TableCell>
  
              </TableRow>
          </TableHead>
          <TableBody>
                
                
  
                  {filteredEmployee.map((employee,index) => (
                    
  
                          <TableRow
                              key={employee._id}
                              style={{
                                  backgroundColor: index % 2 === 0 ? '#ADD8E6' : '#73c3e6', // Use the color codes
                              }}
                          >
                          <TableCell>{ index + 1 }</TableCell>
                          {/* <TableCell>{ goods.goodsCreationdate }</TableCell>  */}
  
  
                          <TableCell
                              style={ {
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              } }
                          >
                              { currentlyEditedRow === employee._id ? (
                                  <TextField
                                      type='date'
                                      value={ editedEmployee[employee._id]?.employeeCreationdate }
                                      onChange={ (e) =>
                                          handleEditChange(employee._id, 'employeeCreationdate', e.target.value)
                                      }
                                  />
                              ) : (
                                          formatDateToFrench(employee.employeeCreationdate)
  
  
                              ) }
                          </TableCell>
  
  
  
                          <TableCell
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}>
  
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeName || employee.employeeName}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Name', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeeName
                              )}
                          </TableCell>
                        
  
  
                          <TableCell>
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeAddress ||  employee.employeeAddress }
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Address', e.target.value)
                                      }
                                  />
                              ) : (
                                      employee.employeeAddress
                              )}
                          </TableCell>
  
  
  
  
  
                          <TableCell
                          
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}>
  
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeContact || employee.employeeCOntact}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Contact', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeeContact
                              )}
                          </TableCell>
  
  
  
                          <TableCell 
                          
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}
                              
                              >
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeNationality || employee.employeeNationality}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Nationality', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeeNationality
                              )}
                          </TableCell>
  
  
                          <TableCell
                          
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}>
  
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeGender || employee.employeeGender}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Gender', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeeGender
                              )}
                          </TableCell>
  
  
                          {/* <TableCell>
                              {currentlyEditedRow === goods._id ? (
                                  <FormControl>
                                      <InputLabel>Current Location</InputLabel>
                                      <Select
                                          value={editedGoods[goods._id]?.employeeComment || goods.employeeComment}
                                          onChange={(e) =>
                                              handleEditChange(goods._id, 'employeeComment', e.target.value)
                                          }
                                      >
                                          <MenuItem value="China">China</MenuItem>
                                          <MenuItem value="Guinea">Guinea</MenuItem>
                                          <MenuItem value="On the Way">On the Way</MenuItem>
                                      </Select>
                                  </FormControl>
                              ) : (
                                  goods.employeeComment
                              )}
                          </TableCell> */}
  
                        
  
                          <TableCell 
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}>
  
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeDepartment || employee.employeeDepartment}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Department', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeeDepartment
                              )}
                          </TableCell>
  
                          <TableCell 
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}>
  
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeePosition || employee.employeePosition}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Position', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeePosition
                              )}
                          </TableCell>
  
                          <TableCell 
                          
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}>
  
  
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeePaidAmount || employee.employeePaidAmount}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Paid Amount', e.target.value)
                                      }
                                  />
                              ) : (
                                  `${formatCurrency(employee.employeePaidAmount)}`
                              )}
                          </TableCell>
  
                          {/* <TableCell>
                              {currentlyEditedRow === goods._id ? (
                                  <FormControl>
                                      <InputLabel>Paid ?</InputLabel>
                                      <Select
                                          value={editedGoods[goods._id]?.clientPaymentStatus || goods.clientPaymentStatus}
                                          onChange={(e) =>
                                              handleEditChange(goods._id, 'clientPaymentStatus', e.target.value)
                                          }
                                      >
                                          <MenuItem value="Not-Paid">Not-Paid</MenuItem>
                                          <MenuItem value="Paid">Paid</MenuItem>
                                      </Select>
                                  </FormControl>
                              ) : (
                                  goods.clientPaymentStatus
                                  
                              )}
                          </TableCell> */}
  
                          <TableCell>
                              <div style={{ whiteSpace: 'nowrap' }}>
  
  
                                  {currentlyEditedRow === employee._id ? (
  
                                      <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => handleUploadButtonClick(employee._id, 'employeeImagesLinks')}
                                          style={{
                                              borderRadius: '50%',  // Make the button round
                                              width: '30px',        // Set the button width
                                              height: '30px',       // Set the button height
                                              padding: '0',         // Remove padding
                                              minWidth: 'unset',    // Remove minWidth
                                          }}
                                      >
                                          +
                                      </Button>
  
  
                                  ) : (
                                      <Button
                                          variant="contained"
                                          color="primary"
                                              onClick={() => handleUploadButtonClick(employee._id, 'employeeImagesLinks')}
                                          style={{
                                              borderRadius: '50%',  // Make the button round
                                              width: '30px',        // Set the button width
                                              height: '30px',       // Set the button height
                                              padding: '0',         // Remove padding
                                              minWidth: 'unset',    // Remove minWidth
                                          }}
                                      >
                                          +
                                      </Button>
  
                                  )}
                                  {employee.employeeImagesLinks.map((imageLink, index) => (
  
                                      <img
                                          key={index}
                                          src={imageLink}
                                          alt={`Image ${index}`}
                                          style={{
                                              display: 'inline-block',
                                              maxWidth: '43px',
                                              maxHeight: '43px',
                                              marginRight: '5px',
                                              borderRadius: '50px', // Set your desired border radius
                                              marginTop: '0',      // Remove marginTop
                                              marginBottom: '0'    // Remove marginBottom
                                          }}
                                          onClick={() => handleImageClick(imageLink,employee._id)}
                                      />
  
                                  ))}
                              </div>
  
                          </TableCell>
  
                          <TableCell 
                          
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}
                              >
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeRemarks || employee.employeeRemarks}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Rearks', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeeRemarks
                              )}
                          </TableCell>
  
  
                          <TableCell
                              style={{
                                  whiteSpace: 'nowrap', // Prevent content from wrapping
                                  overflow: 'hidden',   // Hide overflowing content
                                  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowing content
                                  minWidth: '100px',   // Set a minimum width for the cell
                                  width: 'auto'
                              }}>
  
                              {currentlyEditedRow === employee._id ? (
                                  <TextField
                                      value={editedEmployee[employee._id]?.employeeComment || employee.employeeComment}
                                      onChange={(e) =>
                                          handleEditChange(employee._id, 'employee Comment', e.target.value)
                                      }
                                  />
                              ) : (
                                  employee.employeeComment
                              )}
                          </TableCell>
  
                          <TableCell>
                                                              {currentlyEditedRow === employee._id ? (
                          
                                                                  <>
                                                                      <Button
                                                                      variant="contained"
                                                                      color="primary"
                                                                      onClick={() => saveEditedEmployee(employee._id)}
                                                                  >
                                                                      Save
                                                                  </Button>
                          
                          
                                                                      <Button
                                                                          variant="contained"
                                                                          onClick={() => cancelEdit(employee._id)}
                                                                          style={{ backgroundColor: 'darkred', color: 'white' , marginTop: '5px' }}
                                                                      >
                                                                          Cancel
                                                                      </Button>
                                                                      <Button
                                                                          variant="contained"
                                                                          color="secondary"
                                                                          style={{ backgroundColor: 'red' }}
                                                                          onClick={() => {
                                                                              if (window.confirm('Are you sure you want to delete this employee?')) {
                                                                                  handleDeleteEmployee(employee._id);
                                                                              }
                                                                          }}
                                                                      >
                                                                          Delete
                                                                      </Button>
                          
                                                                  </>
                                                                
                          
                                                                  
                                                              ) : (
                                                                  <Button
                                                                      variant="contained"
                                                                      color="primary"
                                                                      onClick={() => toggleEdit(employee._id)}
                                                                  >
                                                                      Edit
                                                                  </Button>
                                                              )}
                                                          </TableCell>
  
                       
                      </TableRow>
                  ))}
                
          </TableBody>
          
      </Table>
        
  </TableContainer>
  
  </div>
    )
}

export default SalaryTable
