import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import BudgetInfo from './BudgetInfo';
import GoodsStatus from './ChartGoodsStatus';
import ClientsGoods from './ChartClientsGoods';
import ChartExpenses from './ChartExpenses';
import ChartTickets from './ChartTickets';
import ChartUsers from './ChartUsers';




import ExpenseTable from './TableExpense';

const Dashboard = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('userType');
    const isAuthenticated = !!token;

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (userType !== 'employee') {
            navigate('/goodsTableForUser');
        }
    }, [isAuthenticated, navigate]);

    return (
        <div>
            {/* <Typography variant="h4" gutterBottom>
                Dashboard
            </Typography> */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card style={styles.card}>
                        <CardContent>
                            <Typography variant="h6" style={styles.cardTitle}>
                                BUDGETS SUMMARY 
                            </Typography>
                            <BudgetInfo />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card style={styles.card}>
                        <CardContent>
                            <Typography variant="h6" style={styles.cardTitle}>
                                Goods Status 📦
                            </Typography>
                            <GoodsStatus />
                        </CardContent>
                    </Card>
                </Grid>


                <Grid item xs={12} md={6}>
                    <Card style={styles.card}>
                        <CardContent>
                            <Typography variant="h6" style={styles.cardTitle}>
                               Number of Goods by Clients 👩👨
                            </Typography>
                            <ClientsGoods />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card style={styles.card}>
                        <CardContent>
                            <Typography variant="h6" style={styles.cardTitle}>
                                Expenses 💰
                            </Typography>
                            <ChartExpenses />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card style={styles.card}>
                        <CardContent>
                            <Typography variant="h6" style={styles.cardTitle}>
                                Tickets 🎫
                            </Typography>
                            <ChartTickets />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card style={styles.card}>
                        <CardContent>
                            <Typography variant="h6" style={styles.cardTitle}>
                                Users ✨ 
                            </Typography>
                            <ChartUsers />
                        </CardContent>
                    </Card>
                </Grid>


                

                
            </Grid>
        </div>
    );
};

const styles = {
    card: {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: '16px',
    },
    cardTitle: {
        fontWeight: 'bold',
        marginBottom: '8px',
    },
};

export default Dashboard;
