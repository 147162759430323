import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress, Grid,
    FormControl,
    InputLabel,
    Select, TextField,
    MenuItem,
    Button,
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';


function UserTicketsTable() {
    const [tickets, setTickets] = useState([]);
    const [adminComment, setAdminComment] = useState('');
    const [loading, setLoading] = useState(true);
    const [filterStatus, setFilterStatus] = useState(''); // State for ticket status filter
    const [filterType, setFilterType] = useState(''); // State for type of ticket filter
    const [filterUserTel, setFilterUserTel] = useState(''); // State for User Tel filter
    const [editMode, setEditMode] = useState({}); // State for edit mode of each row
    const [userSpecialRole, setUserSpecialRole] = useState({}); // State for edit mode of each row
    const authToken = localStorage.getItem('token');

    useEffect(() => {
        setUserSpecialRole(localStorage.getItem('userSpecialRole'))

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/ticket/listForAdmin`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setTickets(response.data.tickets);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user tickets:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [authToken]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#FFCC80'; // Light Orange
            case 'processing':
                return '#FFFF99'; // Light Yellow
            case 'completed':
                return '#B2FF66'; // Light Green
            case 'cancelled':
                return '#FF9999'; // Light Red
            default:
                return '#E0E0E0'; // Light Gray (default)
        }
    };


    const statusCounts = {
        pending: 0,
        processing: 0,
        completed: 0,
        cancelled: 0,
    };
  
    // Filtered tickets based on selected status, type, and User Tel
    const filteredTickets = tickets
        .filter(
            (ticket) =>
                (!filterStatus || ticket.status === filterStatus) &&
                (!filterType || ticket.typeOfTicket === filterType) &&
                (!filterUserTel || ticket.userTel.includes(filterUserTel))
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    filteredTickets.forEach((task) => {
        statusCounts[task.status]++;
    });

    

    // Handle entering edit mode for a row
    const handleEditClick = (ticketId) => {
        setEditMode({ ...editMode, [ticketId]: true });
    };

    // Handle canceling edit mode for a row
    const handleCancelEdit = (ticketId) => {
        setEditMode({ ...editMode, [ticketId]: false });
    };
    // Handle saving the edited ticket status and adminComment
    const handleSaveEdit = async (ticketId, newStatus) => {
        // console.log(`ADMIN_COMMENT ${adminComment}`);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/ticket/update/${ticketId}`,
                { status: newStatus, adminComment: adminComment },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            if (response.status === 200) {
                // Update the local state with the new status and adminComment
                const updatedTickets = [...tickets];
                const ticketIndex = updatedTickets.findIndex((ticket) => ticket._id === ticketId);
                if (ticketIndex !== -1) {
                    updatedTickets[ticketIndex].status = newStatus;
                    updatedTickets[ticketIndex].adminComment = adminComment;
                    setTickets(updatedTickets);
                }

                // Handle success
                toast.success('update Successfully', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });

                // Exit edit mode
                setEditMode({ ...editMode, [ticketId]: false });
            }
        } catch (error) {
            console.error('Error updating ticket status:', error);
        }
    };

    // Function to handle admin comment change
    // Function to handle admin comment change
    const handleAdminCommentChange = (updatedTicket) => {
        const updatedTickets = [...tickets];
        const ticketIndex = updatedTickets.findIndex((ticket) => ticket._id === updatedTicket._id);

        if (ticketIndex !== -1) {
            updatedTickets[ticketIndex] = updatedTicket;
            setTickets(updatedTickets);
        }
    }



    // Function to delete a ticket by ID
    const handleDeleteTicket = async (ticketId) => {
        const shouldDelete = window.confirm("Are you sure you want to delete this ticket?");

        if (!shouldDelete) {
            return; // If the user cancels the confirmation, do nothing
        }

        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/ticket/delete/${ticketId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            if (response.status === 200) {
                // Remove the deleted ticket from the local state
                const updatedTickets = tickets.filter((ticket) => ticket._id !== ticketId);
                setTickets(updatedTickets);

                // Handle success
                toast.success('Ticket deleted successfully', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
            }
        } catch (error) {
            console.error('Error deleting ticket:', error);
            toast.error('Error deleting ticket');
        }
    };

 



    const labelStyle = {
        transform: filterStatus !== '' || filterType !== '' ? 'translate(7px, -7px)' : 'translate(7px, -7px)',
        fontSize: filterStatus !== '' || filterType !== '' ? 12 : 12,
        backgroundColor: 'white'
        // Add any other styles you need to adjust
    };


    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };
    return (
        <div>
            <Typography variant="h6" style={{ fontSize: '2rem', fontWeight: 'bold' }}>

                All Tickets 🎫
            </Typography>
            <div>

                <Grid
                    style={{
                        marginTop: '10px'
                    }}
                    container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <FormControl style={{ minWidth: 150, marginRight: 20 }}>
                            <InputLabel shrink={filterStatus !== ''} htmlFor="filter-status" style={labelStyle}>
                                Status
                            </InputLabel>
                            <Select
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                inputProps={{
                                    name: 'filter-status',
                                    id: 'filter-status',
                                }}
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="processing">Processing</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                        
                    </Grid>


                    <Grid item xs={6} sm={3}>
                        <FormControl style={{ minWidth: 150 }}>
                            <InputLabel shrink={filterType !== ''} htmlFor="filter-type" style={labelStyle}>
                                Type of Ticket
                            </InputLabel>
                            <Select
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                                inputProps={{
                                    name: 'filter-type',
                                    id: 'filter-type',
                                }}
                            >
                                <MenuItem value="">All</MenuItem>
                                {/* Replace with your actual types of tickets */}
                                <MenuItem value="access">Access</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            label="User Tel"
                            value={filterUserTel}
                            onChange={(e) => setFilterUserTel(e.target.value)}
                        />
                    </Grid>


                </Grid>
                

              
{/*hii  */}
               

            </div>

            <div>
                <Grid
                style={{
                    marginTop: '10px'
                }}
                container spacing={2}>

                    {Object.entries(statusCounts).map(([status, count]) => (

                        <Grid item xs={3} sm={3}>
                            <div key={status}>

                                <Typography
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >{`${status}: ${count}`}</Typography>
                            </div>
                        </Grid>
                    ))}


                </Grid>
            </div>

            <TableContainer
            style={{
                height: '750px',
                marginTop: '19px',
            }}
            component={Paper}>
                <Table  >
                    <TableHead
                        style={{
                            backgroundColor: '#78AEF9',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1000,
                           
                            fontWeight: 'bold', // Apply bold font
                        }}
                    >
                        <TableRow>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Ticket</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Status</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Type</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>User Name</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>User Tel</TableCell>

                            <TableCell style={{
                                fontSize: '0.96rem',
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                            }}>Emergency</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Ticket Content</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Admin Comment</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Created Date</TableCell>
                            <TableCell   style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTickets.map((ticket) => (
                            <TableRow
                                key={ticket._id}
                                style={{ backgroundColor: getStatusColor(ticket.status) }}
                            >
                                <TableCell>{ticket.name}</TableCell>
                                <TableCell>
                                    {editMode[ticket._id] ? (
                                        <Select
                                            value={ticket.status}
                                            onChange={(e) => {
                                                handleSaveEdit(ticket._id, e.target.value);
                                            }}
                                        >
                                            <MenuItem value="pending">Pending</MenuItem>
                                            <MenuItem value="processing">Processing</MenuItem>
                                            <MenuItem value="completed">Completed</MenuItem>
                                            <MenuItem value="cancelled">Cancelled</MenuItem>
                                        </Select>
                                    ) : (
                                        ticket.status
                                    )}
                                </TableCell>
                                <TableCell>{ticket.typeOfTicket}</TableCell>
                                <TableCell>{ticket.userName}</TableCell>
                                <TableCell>{ticket.userTel}</TableCell>
                                <TableCell>{ticket.niveauDurgence || 'N/A'}</TableCell>
                                <TableCell>{ticket.ticketContent}</TableCell>
                                {/* <TableCell>{ticket.adminComment}</TableCell> */}
                                <TableCell>
                                    {editMode[ticket._id] ? (
                                        <div style={{ width: "150px", border: "1px solid #ccc", padding: "4px" }} contentEditable={true} onInput={(e) => setAdminComment(e.target.innerText)}>
                                            {ticket.adminComment}
                                        </div>
                                    ) : (
                                        ticket.adminComment
                                    )}
                                </TableCell>






                                <TableCell>
                                    {/* {new Date(ticket.date).toLocaleString()} */}

                                    {formatDateToFrench(ticket.date)}
                                </TableCell>
                                <TableCell>
                                    {editMode[ticket._id] ? (
                                        <>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => {
                                                    handleSaveEdit(ticket._id, ticket.status);
                                                }}
                                            >
                                                Save
                                            </Button>

                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleDeleteTicket(ticket._id)}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleCancelEdit(ticket._id)}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    ) : (
                                        userSpecialRole === 'IT' || 'admin' && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleEditClick(ticket._id)}
                                            >
                                                Edit
                                            </Button>
                                        )
                                    )}

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <ToastContainer position="top-center" autoClose={5000} />

            </TableContainer>
        </div>
    );
}

export default UserTicketsTable;
