import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Button,
    Grid,
    Typography,
    CircularProgress,
    Modal,
    Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { Image } from 'cloudinary-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListIcon from '@mui/icons-material/List';
import Fab from '@mui/material/Fab';

const initialFormData = {
    assetNumber: '',
    assetName: '',
    assetDescription: '',
    quantity: '',
    assetPrice: '',
    assetTotal: '',
    dateBought: '',
    boughtBy: ''
};

const AssetForm = () => {
    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        // For quantity and price fields, auto-calculate total
        if (name === 'quantity' || name === 'assetPrice') {
            const updatedFormData = {
                ...formData,
                [name]: value
            };
            
            // Calculate total if both quantity and price are set
            if (updatedFormData.quantity && updatedFormData.assetPrice) {
                updatedFormData.assetTotal = (
                    parseFloat(updatedFormData.quantity) * 
                    parseFloat(updatedFormData.assetPrice)
                ).toString();
            }
            
            setFormData(updatedFormData);
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const validateForm = () => {
        const requiredFields = [
            'assetNumber',
            'assetName',
            'assetDescription',
            'quantity',
            'assetPrice',
            'assetTotal',
            'dateBought',
            'boughtBy'
        ];

        const missingFields = requiredFields.filter(field => !formData[field]);

        if (missingFields.length > 0) {
            toast.error(`Please fill in all required fields: ${missingFields.join(', ')}`, {
                position: 'top-center',
                autoClose: 5000,
            });
            return;
        }
        
        // Validate numeric fields
        if (isNaN(parseFloat(formData.quantity)) || parseFloat(formData.quantity) <= 0) {
            toast.error('Quantity must be a positive number', {
                position: 'top-center',
                autoClose: 5000,
            });
            return false;
        }
        
        if (isNaN(parseFloat(formData.assetPrice)) || parseFloat(formData.assetPrice) <= 0) {
            toast.error('Price must be a positive number', {
                position: 'top-center',
                autoClose: 5000,
            });
            return false;
        }
        
        return true;
    };

    const handleSubmit = async () => {

        try {
            setIsSubmitting(true);
            const authToken = localStorage.getItem('token');
            const authorizationHeader = `Bearer ${authToken}`;

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/asset/create`, { // Corrected URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorizationHeader,
                },
                body: JSON.stringify({
                    ...formData,
                }),
            });

            const responseText = await response.text(); // Get response as text
            console.log('Response:', responseText); // Log the response for debugging
            console.log('Response status:', response.status)
            // const data = JSON.parse(responseText); // Parse the response text as JSON

            // Only try to parse as JSON if it's valid JSON
    let data;
    try {
        data = JSON.parse(responseText);
    } catch (parseError) {
        console.error('Response is not valid JSON:', responseText.substring(0, 100) + '...');
        throw new Error('Invalid JSON response from server');
    }

            if (response.ok) {
                toast.success('Asset Submitted Successfully', {
                    position: 'top-center',
                    autoClose: 3000,
                });

                // Reset form after successful submission
                setFormData(initialFormData);
                // setUploadedNationalIdImages([]);

                // Navigate to employee table after 3 seconds
                setTimeout(() => {
                    navigate('/assetTable', { replace: true });
                }, 3000);
            } else {
                toast.error(data.message || 'Failed to Submit Asset', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                console.error('Failed to Submit Asset', data);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form', {
                position: 'top-center',
                autoClose: 5000,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGoToAssetTableClick = () => {
        navigate('/assetTable', { replace: true });
    };

    const handleOpenModal = () => {
        if (validateForm()) {
            setIsModalOpen(true);
        }
    };
    
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <Container>
            <Typography variant="h6" style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px' }}>
                New Asset Registration
            </Typography>

            <form onSubmit={(e) => { e.preventDefault(); handleOpenModal(); }}>
                <Grid container spacing={2}>
                    {/* Asset Information */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Item Name *"
                            name="assetName"
                            value={formData.assetName}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Description *"
                            name="assetDescription"
                            value={formData.assetDescription}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Quantity *"
                            name="quantity"
                            type="number"
                            inputProps={{ min: "1", step: "1" }}
                            value={formData.quantity}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Price *"
                            name="assetPrice"
                            type="number"
                            inputProps={{ min: "0.01", step: "0.01" }}
                            value={formData.assetPrice}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Total *"
                            name="assetTotal"
                            type="number"
                            value={formData.assetTotal}
                            onChange={handleChange}
                            disabled={true} // Calculated field, disabled for direct input
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Date Bought *"
                            name="dateBought"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={formData.dateBought}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Bought By *"
                            name="boughtBy"
                            value={formData.boughtBy}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Asset No *"
                            name="assetNumber"
                            value={formData.assetNumber}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                        />
                    </Grid>

                    {/* Image Upload */}
                    {/* <Grid item xs={12}>
                        <Dropzone 
                            onDrop={handleAsstImageUpload} 
                            accept={{ 'image/*': [] }}
                            multiple={false}
                            disabled={isSubmitting || isUploading || !envVarsLoaded}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: '1px dashed #ccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                        cursor: (isSubmitting || isUploading || !envVarsLoaded) ? 'not-allowed' : 'pointer',
                                        backgroundColor: !envVarsLoaded ? '#f5f5f5' : 'transparent'
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {isUploading ? (
                                        <div>
                                            <CircularProgress size={24} />
                                            <p>Uploading image...</p>
                                        </div>
                                    ) : !envVarsLoaded ? (
                                        <p>Image upload unavailable - Cloudinary configuration missing</p>
                                    ) : (
                                        <p>Click or drag to upload an image (optional)</p>
                                    )}
                                    
                                    {formData.assetImage && formData.assetImage.length > 0 && (
                                        <div style={{ marginTop: '10px' }}>
                                            {formData.assetImage.map((img, index) => (
                                                <div key={index} style={{ display: 'inline-block', margin: '5px', position: 'relative' }}>
                                                    <img 
                                                        src={img} 
                                                        alt="Asset preview"
                                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        size="small"
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            minWidth: '30px',
                                                            width: '30px',
                                                            height: '30px',
                                                            padding: 0
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const newImages = [...formData.assetImage];
                                                            newImages.splice(index, 1);
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                assetImage: newImages
                                                            }));
                                                            setUploadedAssetImage(newImages);
                                                        }}
                                                        disabled={isSubmitting || isUploading}
                                                    >
                                                        ×
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Dropzone>
                    </Grid> */}
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    style={{ marginTop: '20px', position: 'relative' }}
                    disabled={isSubmitting || isUploading}
                    type="button"
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        'Submit Asset'
                    )}
                </Button>

                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography id="modal-title" variant="h6" component="h2">
                            Confirm Submission
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to submit this asset's information?
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleSubmit();
                                handleCloseModal();
                            }}
                            style={{ marginTop: '20px' }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                'Confirm'
                            )}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseModal}
                            style={{ marginTop: '20px', marginLeft: '10px' }}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Modal>

                <ToastContainer position="top-center" autoClose={5000} />

                <Fab 
                    color="secondary" 
                    size="small"
                    style={{
                        position: 'fixed',
                        top: '90px', left: 'calc(50% - 50px)',
                        zIndex: 999, transform: 'translateX(-50%)',
                        background: 'linear-gradient(90deg, lightcoral, blue)',
                        marginRight: '10px', borderRadius: '50%'
                    }}
                    onClick={handleGoToAssetTableClick}
                    disabled={isSubmitting}
                >
                    <ListIcon />
                </Fab>
            </form>
        </Container>
    );
};

export default AssetForm;