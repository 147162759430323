import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Typography,
    MenuItem,
    CircularProgress,
    Modal,
    Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { Image } from 'cloudinary-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListIcon from '@mui/icons-material/List';
import Fab from '@mui/material/Fab';

const initialFormData = {
    employeeName: '',
    employeeEmail: '',
    employeeAddress: '',
    employeeContact: '',
    employeeNationality: 'Guinean',
    employeeGender: 'male',
    employeeDepartment: 'it',
    employeePosition: '',
    employeeAsset: '',
    employeeSalary: '',
    employeeNationalIdImages: [],
    contractStartDate: '',
    contractEndDate: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    employmentStatus: 'active'
};

const EmployeeForm = () => {
    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadedNationalIdImages, setUploadedNationalIdImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleNationalIdUpload = async (files) => {
        try {
            const uploadPromises = files.map((file) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);

                return fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, {
                    method: 'POST',
                    body: formData,
                })
                .then((response) => response.json())
                .then((data) => data.secure_url);
            });

            const imageUrls = await Promise.all(uploadPromises);
            setUploadedNationalIdImages(imageUrls);
            setFormData(prevState => ({
                ...prevState,
                employeeNationalIdImages: imageUrls
            }));

            toast.success('National ID Image(s) uploaded successfully', {
                position: 'top-center',
                autoClose: 3000,
            });
        } catch (error) {
            console.error('Error uploading national ID images:', error);
            toast.error('Failed to upload national ID images', {
                position: 'top-center',
                autoClose: 3000,
            });
        }
    };

    const handleSubmit = async () => {
        // Validate required fields
        const requiredFields = [
            'employeeName', 
            'employeeEmail',
            'employeeAddress',
            'employeeContact', 
            'employeeNationality', 
            'employeeGender', 
            'employeeDepartment',
            'employeePosition',
            'employeeAsset',
            'employeeSalary',
            'contractStartDate',
            'contractEndDate',
            'employmentStatus',
            'emergencyContactName',
            'emergencyContactPhone',
            'employeeNationalIdImages'

        ];

        const missingFields = requiredFields.filter(field => !formData[field]);

        if (missingFields.length > 0) {
            toast.error(`Please fill in all required fields: ${missingFields.join(', ')}`, {
                position: 'top-center',
                autoClose: 5000,
            });
            return;
        }

        try {
            setIsSubmitting(true);
            const authToken = localStorage.getItem('token');
            const authorizationHeader = `Bearer ${authToken}`;

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/employees/create`, { // Corrected URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorizationHeader,
                },
                body: JSON.stringify({
                    ...formData,
                    employeeNationalIdImages: uploadedNationalIdImages // Ensure image URLs are included
                }),
            });

            const responseText = await response.text(); // Get response as text
            console.log('Response:', responseText); // Log the response for debugging

            const data = JSON.parse(responseText); // Parse the response text as JSON

            if (response.ok) {
                toast.success('Employee Submitted Successfully', {
                    position: 'top-center',
                    autoClose: 3000,
                });

                // Reset form after successful submission
                setFormData(initialFormData);
                setUploadedNationalIdImages([]);

                // Navigate to employee table after 3 seconds
                setTimeout(() => {
                    navigate('/employeeTable', { replace: true });
                }, 3000);
            } else {
                toast.error(data.message || 'Failed to Submit Employee', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                console.error('Failed to Submit Employee', data);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form', {
                position: 'top-center',
                autoClose: 5000,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGoToEmployeeTableClick = () => {
        navigate('/employeeTable', { replace: true });
    };

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <Container>
            <Typography variant="h6" style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px' }}>
                New Employee Registration
            </Typography>

            <form>
                <Grid container spacing={2}>
                    {/* Personal Information */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Full Name *"
                            name="employeeName"
                            value={formData.employeeName}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Email *"
                            name="employeeEmail"
                            type="email"
                            value={formData.employeeEmail}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Contact Number *"
                            name="employeeContact"
                            value={formData.employeeContact}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Address *"
                            name="employeeAddress"
                            value={formData.employeeAddress}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Nationality and Gender */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Nationality *</InputLabel>
                            <Select
                                label="Nationality *"
                                name="employeeNationality"
                                value={formData.employeeNationality}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="Guinean">Guinean</MenuItem>
                                <MenuItem value="Sierra Leonean">Sierra Leonean</MenuItem>
                                <MenuItem value="Ivorian">Ivory Coast</MenuItem>
                                <MenuItem value="Malian">Mali</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Gender *</InputLabel>
                            <Select
                                label="Gender *"
                                name="employeeGender"
                                value={formData.employeeGender}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Employment Details */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Department *</InputLabel>
                            <Select
                                label="Department *"
                                name="employeeDepartment"
                                value={formData.employeeDepartment}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="it">IT</MenuItem>
                                <MenuItem value="commercial">Commercial</MenuItem>
                                <MenuItem value="communication">Communication</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Position *"
                            name="employeePosition"
                            value={formData.employeePosition}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Asset Assigned"
                            name="employeeAsset"
                            value={formData.employeeAsset}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Monthly Salary *"
                            name="employeeSalary"
                            type="number"
                            value={formData.employeeSalary}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Employment Status and Dates */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Employment Status</InputLabel>
                            <Select
                                label="Employment Status *"
                                name="employmentStatus"
                                value={formData.employmentStatus}
                                onChange={handleChange}
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="probation">Probation</MenuItem>
                                <MenuItem value="suspended">Suspended</MenuItem>
                                <MenuItem value="terminated">Terminated</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Contract Start Date *"
                            name="contractStartDate"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={formData.contractStartDate}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Contract End Date *"
                            name="contractEndDate"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={formData.contractEndDate}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Emergency Contact */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Emergency Contact Name *"
                            name="emergencyContactName"
                            value={formData.emergencyContactName}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Emergency Contact Phone *"
                            name="emergencyContactPhone"
                            value={formData.emergencyContactPhone}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Document Uploads */}
                    <Grid item xs={12}>
                        <Dropzone 
                            onDrop={handleNationalIdUpload} 
                            accept="image/*" 
                            multiple
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: '1px dashed #ccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <p>Click or drag to upload National ID/Passport</p>
                                    {uploadedNationalIdImages.length > 0 && (
                                        <div style={{ marginTop: '10px' }}>
                                            {uploadedNationalIdImages.map((imageUrl, index) => (
                                                <Image
                                                    key={index}
                                                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                                                    publicId={imageUrl}
                                                    width="100"
                                                    style={{ margin: '5px' }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Dropzone>
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    style={{ marginTop: '20px', position: 'relative' }}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        'Submit Employee'
                    )}
                </Button>

                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography id="modal-title" variant="h6" component="h2">
                            Confirm Submission
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to submit this employee's information?
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleSubmit();
                                handleCloseModal();
                            }}
                            style={{ marginTop: '20px' }}
                        >
                            Confirm
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseModal}
                            style={{ marginTop: '20px', marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Modal>

                <ToastContainer position="top-center" autoClose={5000} />

                <Fab 
                    color="secondary" 
                    size="small"
                    style={{
                        position: 'fixed',
                        top: '90px', left: 'calc(50% - 50px)',
                        zIndex: 999, transform: 'translateX(-50%)',
                        background: 'linear-gradient(90deg, lightcoral, blue)',
                        marginRight: '10px', borderRadius: '50%'
                    }}
                    onClick={() => handleGoToEmployeeTableClick()}>
                    <ListIcon />
                </Fab>
            </form>
        </Container>
    );
}

export default EmployeeForm;