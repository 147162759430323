import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, MenuItem,
    TableRow,
    Paper, Grid, Typography,
    TextField, Select,
    Button, FormControl,
InputLabel,
DatePicker
} from '@mui/material';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import styled from 'styled-components';

import TaskForm from '../forms/TaskForm'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TaskTable = () => {
    const [tasks, setTasks] = useState([]);
    const [editedTask, setEditedTask] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [showForm, setShowForm] = useState(false); // New state to control form visibility
    const [filterStatus, setFilterStatus] = useState(''); // State for filtering status
    const [filterCreatedDate, setFilterCreatedDate] = useState(""); // State for filtering created date

    const [filterStartDate, setFilterStartDate] = useState(""); // State for filtering start date
    const [filterEndDate, setFilterEndDate] = useState(""); // State for filtering end date

    


    const fetchTasks = () => {
        const authToken = localStorage.getItem('token');

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/task/allTasks`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                setTasks(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error('Error fetching tasks:', error);
            });
    };

    useEffect(() => {
        fetchTasks();
    }, []); // Trigger on component mount


    const handleEditClick = (task) => {
        setEditedTask({ ...task });
        setIsEditing(true);
    };
 
    const handleSaveClick = () => {
     
        // Calculate total amount
        const totalAmount = parseInt(editedTask.quantity) * parseInt(editedTask.unitPrice);
        editedTask.totalAmount = totalAmount;

        const authToken = localStorage.getItem('token');
        axios
            .put(`${process.env.REACT_APP_BASE_URL}/task/update/${editedTask._id}`, editedTask, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                // Update the local state with the edited task
                const updatedTasks = tasks.map((task) =>
                    task._id === editedTask._id ? editedTask : task
                );
                setTasks(updatedTasks);
                setIsEditing(false);
                setEditedTask(null);

                toast.success('Task updated successfully', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
            })
            .catch((error) => {
                toast.error('Task update error', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                console.error('Error updating task:', error);
            });
    };

    const generateExcelData = () => {
        const data = [];
        data.push([
            'Created Date',
            'Task Name',
            'Urgency',
            'Description',
            'Progress',
            'Status',
            'Supplier',
            'Responsible',
            'Quantity',
            'Unit Price',
            'Total Amount',
            'Deadline',
            'Closing Date',
            'Remark',
        ]);

        sortedTasks.forEach((task) => {
            data.push([
                formatDateToFrench(task.date),
                task.taskName,
                task.niveauDurgence,
                task.actions,
                task.progress,
                task.status,
                task.supplier,
                task.responsible,
                task.quantity,
                task.unitPrice,
                task.totalAmount,
                formatDateToFrench(task.deadline),
                formatDateToFrench(task.closingDate),
                task.remark,
            ]);
        });

        return data;
    };

    const handleDownloadExcel = () => {
        const data = generateExcelData();
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Tasks');
        XLSX.writeFile(wb, 'tasks.xlsx');
    };

    const handleUploadExcel = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
    
                const newTasks = worksheet.slice(1).map((row) => ({
                    date: row[0] ? new Date(row[0]).toISOString() : null,
                    taskName: row[1] || '',
                    niveauDurgence: row[2] || '',
                    actions: row[3] || '',
                    progress: row[4] || '',
                    status: row[5] || '',
                    supplier: row[6] || '',
                    responsible: row[7] || '',
                    quantity: row[8] || 0,
                    unitPrice: row[9] || 0,
                    totalAmount: row[10] || 0,
                    deadline: row[11] ? new Date(row[11]).toISOString() : null,
                    closingDate: row[12] ? new Date(row[12]).toISOString() : null,
                    remark: row[13] || '',
                }));
    
                const authToken = localStorage.getItem('token');
                await axios.post(`${process.env.REACT_APP_BASE_URL}/task/uploadTasks`, newTasks, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
    
                setTasks((prevTasks) => [...prevTasks, ...newTasks]);
                toast.success('Tasks uploaded successfully', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
            } catch (error) {
                console.error('Error uploading tasks:', error);
                toast.error('Error uploading tasks. Please try again.', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
            }
        };
    
        reader.onerror = (error) => {
            console.error('File reading error:', error);
            toast.error('Error reading file. Please try again.', {
                position: 'top-center',
                autoClose: 5000, // 5 seconds
            });
        };
    
        reader.readAsArrayBuffer(file);
    };

    function formatDateForInput(date) {
        if (date instanceof Date) {
            const year = date.getFullYear();
            let month = (date.getMonth() + 1).toString();
            if (month.length === 1) {
                month = `0${month}`;
            }
            let day = date.getDate().toString();
            if (day.length === 1) {
                day = `0${day}`;
            }
            return `${year}-${month}-${day}`;
        }
        return '';
    }


    const statusCounts = {
        pending: 0,
        processing: 0,
        completed: 0,
        reported: 0,
        cancelled: 0,
        blocked: 0,
    };
 

    // const filteredTasks = tasks.filter((task) => {
    //     const isStatusMatch = filterStatus === '' || task.status === filterStatus;
    //     const isDateMatch = filterCreatedDate === '' || new Date(task.date).toDateString() === new Date(filterCreatedDate).toDateString();
    //     return isStatusMatch && isDateMatch;
    // });


    const filteredTasks = tasks.filter((task) => {
        const isStatusMatch = filterStatus === '' || task.status === filterStatus;
        let isDateMatch = true;

        if (filterStartDate && filterEndDate) {
            const taskDate = new Date(task.date);
            const startDate = new Date(filterStartDate);
            const endDate = new Date(filterEndDate);
            isDateMatch = taskDate >= startDate && taskDate <= endDate;
        }

        return isStatusMatch && isDateMatch;
    });

    const sortedTasks = filteredTasks.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Count the tasks for each status
    sortedTasks.forEach((task) => {
        statusCounts[task.status]++;
    });

    const handleDeleteTask = async (taskId) => {
        const authToken = localStorage.getItem('token');

        // Use window.confirm to ask for confirmation
        const shouldDelete = window.confirm('Are you sure you want to delete this task?');

        if (shouldDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/task/delete/${taskId}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                // You can handle success or display a message to the user here.
                console.log(`Task ${taskId} deleted successfully`);

                toast.success('Task Deleted successfully', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });

                // After successful delete, refetch the tasks to update the list
                fetchTasks();
            } catch (error) {
                toast.error('Task Deleted error. Try Again', {
                    position: 'top-center',
                    autoClose: 5000, // 5 seconds
                });
                // Handle the error or display an error message
                console.error(`Error deleting task ${taskId}:`, error);
            }
        }
    };

    // import { styled } from '@mui/system';

    const StyledSaveButton = styled(Button)(({ theme }) => ({
        background: 'linear-gradient(45deg, #4CAF50 30%, #388E3C 90%)',
        color: 'white',
        margin: '8px',
    }));

    const StyledCancelButton = styled(Button)(({ theme }) => ({
        background: 'linear-gradient(45deg, #FFD54F 30%, #FFC107 90%)',
        color: 'white',
        margin: '8px',
    }));

    const StyledDeleteButton = styled(Button)(({ theme }) => ({
        background: 'linear-gradient(45deg, #8B0000 30%, #660000 90%)',
        color: 'white',
        margin: '8px',
    }));

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case 'pending':
                return '#C2C4C0'; // Gray
            case 'completed':
                return '#76F10A'; // Light Green

            case 'processing':
                return '#E9D20D'; // yellow
     
            case 'reported':
                return '#FB815D'; // Light Red

            case 'cancelled':
                return '#FB815D'; // Light Red

            case 'blocked':
                return '#FB815D'; // Light Red

                
            default:
                return '';
        }
    };



    const applyBackgroundColor = (status) => {
        // const backgroundColor = getStatusBackgroundColor(status);
        return {
            backgroundColor: getStatusBackgroundColor(status),
            borderBottom: `1px solid black`, // Add this line for the bottom border
        };
    };



    const [showFilters, setShowFilters] = useState(false);

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };
  

    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };



    return (

        <>
            <Typography variant="h6" style={{ fontSize: '2rem', fontWeight: 'bold' }}>

                Tasks 👩‍🏭
            </Typography>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowForm(!showForm)}
                style={{ margin: '1rem' }}
            >
                {showForm ? 'Show Table' : 'Add New Task'} {/* Toggle the button text */}
            </Button>


           

            {showForm ? (
                <TaskForm
                // Pass any necessary props or functions to the TaskForm component here
                />
            ) : (
                    <TableContainer 
                        style={{
                            height: '750px'
                        }}
                        // style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1000 }}
                    component={Paper}>


                        <Button
                            variant="contained"
                            onClick={toggleFilters}
                            style={{
                                backgroundColor: 'blue',
                                color: 'white',
                                minWidth: '120px',
                                marginBottom: '10px',
                            }}
                        >
                            {showFilters ? 'Hide Filters' : 'Show Filters'}
                        </Button>
                        {
                            showFilters && (
                                <div style={{ margin: '1rem' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                        <div>
                                            <FormControl style={{ minWidth: 150, margin: '5px' }}>
                                                <InputLabel
                                                    style={{ background: 'white' }}
                                                >Status</InputLabel>
                                                <Select
                                                    value={filterStatus}
                                                    onChange={(e) => setFilterStatus(e.target.value)}
                                                >
                                                    <MenuItem value="">All</MenuItem>
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                    <MenuItem value="processing">Processing</MenuItem>
                                                    <MenuItem value="completed">Completed</MenuItem>
                                                    <MenuItem value="reported">Reported</MenuItem>
                                                    <MenuItem value="cancelled">Cancelled</MenuItem>
                                                    <MenuItem value="blocked">Blocked</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/* <TextField
                                                style={{ margin: '5px' }}
                                                label="Created Date"
                                                type="date"
                                                value={filterCreatedDate}
                                                onChange={(e) => setFilterCreatedDate(e.target.value)}
                                                InputLabelProps={{ shrink: true }}

                                            /> */}

                                            <TextField
                                                style={ { margin: '5px' } }
                                                label="Start Date"
                                                type="date"
                                                value={ filterStartDate }
                                                onChange={ (e) => setFilterStartDate(e.target.value) }
                                                InputLabelProps={ { shrink: true } }
                                            />

                                            <TextField
                                                style={ { margin: '5px' } }
                                                label="End Date"
                                                type="date"
                                                value={ filterEndDate }
                                                onChange={ (e) => setFilterEndDate(e.target.value) }
                                                InputLabelProps={ { shrink: true } }
                                            />


                                        </div>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#8B0000', color: 'white', margin: '5px' }}
                                            onClick={() => {
                                                setFilterStatus('');
                                                setFilterCreatedDate('');
                                                setFilterStartDate('');
                                                setFilterEndDate('')
                                            }}
                                        >
                                            Reset Filters
                                        </Button>

                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'blue', color: 'white', margin: '5px' }}
                                            onClick={handleDownloadExcel} // Add this line to handle the download
                                        >
                                            Download
                                        </Button>

                                        <Button
                                            variant="contained"
                                            component="label"
                                            style={{ backgroundColor: 'green', color: 'white', margin: '5px' }}
                                        >
                                            Upload
                                            <input
                                                type="file"
                                                accept=".xlsx, .xls"
                                                hidden
                                                onChange={handleUploadExcel}
                                            />
                                        </Button>
                                    </div>

                                    {/* Grid component here */}
                                    <Grid container spacing={2}>
                                        {/* Your Grid items */}
                                    </Grid>
                                </div>

            )
                        }

                        {/* Display the count for each status */}
                        <div>
                            <Typography> Count by Status:</Typography>
                            <Grid container spacing={2}>
                           
                                    {Object.entries(statusCounts).map(([status, count]) => (
                                        
                                        <Grid item xs={3} sm={3}>
                                        <div key={status}>
                                       
                                            <Typography
                                            style={{
                                              fontWeight: 'bold',
                                            }}
                                            >{`${status}: ${count}`}</Typography>
                                        </div>
                                         </Grid>
                                    ))}
                      
                    
                            </Grid>
                        </div>


                        
                           
                     


                        <Table 
                    
                            // style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1000 }}
                        >
                            <TableHead
                                style={{
                                    backgroundColor: '#78AEF9',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1000,
                                    fontWeight: 'bold', // Apply bold font
                                }}
                            >

                                
                                <TableRow>
                                    {/* <TableCell>ID</TableCell> */}
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Created Date</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Task Name</TableCell>

                                    <TableCell style={{
                                        fontSize: '0.96rem',
                                        fontFamily: 'monospace',
                                        fontWeight: 'bold',
                                    }}>Urgency</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Description</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Progress</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Status</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Supplier</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Responsible</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Quantity</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Unit Price</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Total Amount</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Deadline</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Closing Date</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Remark</TableCell>
                                    <TableCell  style={{
                fontSize: '0.96rem',
                fontFamily: 'monospace',
                fontWeight: 'bold',
            }}>Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedTasks.map((task) => (
                                    <TableRow 
                                        style={applyBackgroundColor(task.status)}

                                    key={task._id}>
                                        {/* <TableCell>{task._id.slice(-6)}</TableCell> */}
                                        {/* <TableCell>{new Date(task.date).toLocaleString()}</TableCell> */}

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        type="date"
                                                        fullWidth
                                                        onChange={(e) => {
                                                            const newDate = e.target.value
                                                                ? new Date(e.target.value + 'T00:00:00.000Z')
                                                                : null;

                                                            setEditedTask({
                                                                ...editedTask,
                                                                date: newDate,
                                                            });
                                                        }}
                                                        value={formatDateForInput(editedTask.date)}
                                                    />
                                                </Grid>
                                            ) : (

                                                    // new Date(task.date).toLocaleString()
                                                    formatDateToFrench(task.date)
                                            )}
                                        </TableCell>


                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    value={editedTask.taskName}
                                                    style={{ width: '126px' }}
                                                    onChange={(e) =>
                                                        setEditedTask({ ...editedTask, taskName: e.target.value })
                                                    }
                                                />
                                            ) : (
                                                task.taskName
                                            )}
                                        </TableCell>




                                          <TableCell>
                                            {isEditing && task._id === editedTask._id ? (

                                                <>
                                                    <FormControl style={{  minWidth: '100%' }}>
                                                        <InputLabel> niveau D'Urgence * </InputLabel>
                                                        <Select
                                                            label="niveauDurgence"
                                                            name="niveauDurgence"
                                                            value={editedTask.niveauDurgence}
                                                            style={{width: '126px' }}

                                                            onChange={(e) =>
                                                                setEditedTask({ ...editedTask, niveauDurgence: e.target.value })
                                                            }
                                                            fullWidth

                                                        >
                                                            <MenuItem value="Urgent">Urgent</MenuItem>
                                                            <MenuItem value="Moin Urgent">Moin Urgent</MenuItem>
                                                            <MenuItem value="Pas Urgent">Pas Urgent</MenuItem>
                                                        </Select>
                                                    </FormControl>


      
                                                </>
                                            ) : (
                                                <>
                                                

                                                        {task.niveauDurgence ? (
                                                            task.niveauDurgence
                                                        ) : (
                                                            "N/A"
                                                        )}
                                                </>
                                            )}
                                        </TableCell>

 
                                       

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <textarea
                                                    value={editedTask.actions}
                                                    style={{ width: '226px', height: 'auto', whiteSpace: 'pre-line', overflow: 'hidden' }}
                                                    onChange={(e) =>
                                                        setEditedTask({ ...editedTask, actions: e.target.value })
                                                    }
                                                    rows={editedTask.actions.split('\n').length} // Set the number of rows based on content
                                                />
                                            ) : (
                                                <Typography 
                                                style={{
                                                    fontSize:  '0.9rem',
                                                    fontFamily: 'unset'
                                                }}
                                                >
                                                    <pre>{task.actions}</pre>
                                                </Typography>
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <textarea
                                                    value={editedTask.progress}
                                                    style={{ width: '226px', height: 'auto', whiteSpace: 'pre-line', overflow: 'hidden' }}
                                                    onChange={(e) =>
                                                        setEditedTask({ ...editedTask, progress: e.target.value })
                                                    }
                                                    rows={editedTask.progress.split('\n').length} // Set the number of rows based on content
                                                />
                                            ) : (
                                                <Typography 
                                                        style={{
                                                            fontSize: '0.9rem',
                                                            fontFamily: 'unset'
                                                        }}
                                                >
                                                    <pre>{task.progress}</pre>
                                                </Typography>
                                            )}
                                        </TableCell>


                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                          
                                                <Select
                                                    value={editedTask.status}
                                                    onChange={(e) =>
                                                        setEditedTask({ ...editedTask, status: e.target.value })
                                                    }
                                                >
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                    <MenuItem value="processing">Processing</MenuItem>
                                                    <MenuItem value="completed">Completed</MenuItem>
                                                    <MenuItem value="reported">Reported</MenuItem>
                                                    <MenuItem value="blocked">blocked</MenuItem>

                                                    <MenuItem value="cancelled">Cancelled</MenuItem>

                                                </Select>


                                            ) : (
                                                task.status
                                            )}
                                        </TableCell>



                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    style={{ width: '206px' }}

                                                    value={editedTask.supplier}
                                                    onChange={(e) =>
                                                        setEditedTask({ ...editedTask, supplier: e.target.value })
                                                    }
                                                />
                                            ) : (
                                                task.supplier
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    style={{ width: '206px' }}

                                                    value={editedTask.responsible}
                                                    onChange={(e) =>
                                                        setEditedTask({ ...editedTask, responsible: e.target.value })
                                                    }
                                                />
                                            ) : (
                                                task.responsible
                                            )}
                                        </TableCell>
                                        {/* <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    style={{ width: '106px' }}
                                                    value={editedTask.quantity}
                                                    onChange={(e) => setEditedTask({ ...editedTask, quantity: e.target.value })}
                                                />
                                            ) : (
                                                task.quantity || "N/A"
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    style={{ width: '106px' }}
                                                    value={editedTask.unitPrice}
                                                    onChange={(e) => setEditedTask({ ...editedTask, unitPrice: e.target.value })}
                                                />
                                            ) : (
                                                task.unitPrice || "N/A"
                                            )}
                                        </TableCell>
{/*  */}

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    style={{ width: '106px' }}
                                                    value={editedTask.quantity}
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        // Check if the input is a positive digit
                                                        if (/^\d+$/.test(input) && parseInt(input, 10) >= 0) {
                                                            setEditedTask({ ...editedTask, quantity: input });
                                                        }
                                                        // You can also add an else block to handle invalid input (e.g., show an error message)
                                                    }}
                                                />
                                            ) : (
                                                task.quantity || "N/A"
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    style={{ width: '106px' }}
                                                    value={editedTask.unitPrice}
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        // Check if the input is a positive digit
                                                        if (/^\d+$/.test(input) && parseInt(input, 10) >= 0) {
                                                            setEditedTask({ ...editedTask, unitPrice: input });
                                                        }
                                                        // You can also add an else block to handle invalid input (e.g., show an error message)
                                                    }}
                                                />
                                            ) : (
                                                task.unitPrice || "N/A"
                                            )}
                                        </TableCell>

                                        {/* <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextField
                                                    style={{ width: '106px' }}
                                                    value={editedTask.totalAmount}
                                                    onChange={(e) => setEditedTask({ ...editedTask, totalAmount: e.target.value })}
                                                />
                                            ) : (
                                                task.totalAmount || "N/A"
                                            )}
                                        </TableCell>  */}


                                        <TableCell>
                                             
                                                {task.totalAmount || "N/A"}
                                           
                                        </TableCell>

                                      
                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        type="date"
                                                        fullWidth
                                                        onChange={(e) => {
                                                            const newDeadline = e.target.value
                                                                ? new Date(e.target.value + 'T00:00:00.000Z')
                                                                : null;

                                                            setEditedTask({
                                                                ...editedTask,
                                                                deadline: newDeadline,
                                                            });
                                                        }}
                                                        value={formatDateForInput(editedTask.deadline)}
                                                    />
                                                </Grid>
                                            ) : (

                                                // new Date(task.deadline).toLocaleString()
                                                    formatDateToFrench(task.deadline)

                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        type="date"
                                                        fullWidth
                                                        onChange={(e) => {
                                                            const newClosingDate = e.target.value
                                                                ? new Date(e.target.value + 'T00:00:00.000Z')
                                                                : null;

                                                            setEditedTask({
                                                                ...editedTask,
                                                                closingDate: newClosingDate,
                                                            });
                                                        }}
                                                        value={formatDateForInput(editedTask.closingDate)}

                                                    />
                                                </Grid>
                                            ) : (
                                                // new Date(task.closingDate).toLocaleString()
                                                    formatDateToFrench(task.closingDate)

                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <TextareaAutosize
                                                    value={editedTask.remark}
                                                    style={{ width: '226px', whiteSpace: 'pre-line' }}
                                                    onChange={(e) =>
                                                        setEditedTask({ ...editedTask, remark: e.target.value })
                                                    }
                                                    minRows={3}
                                                />
                                            ) : (
                                                <Typography 
                                                        style={{
                                                            fontSize: '0.9rem',
                                                            fontFamily: 'unset'
                                                        }}
                                                >
                                                        <pre>{task.remark}</pre>
                                                </Typography>
                                            )}
                                        </TableCell>


                                        <TableCell>
                                            {isEditing && task._id === editedTask._id ? (
                                                <>
                                                    <StyledSaveButton onClick={handleSaveClick}>Save</StyledSaveButton>
                                                    <StyledCancelButton onClick={() => {
                                                        setIsEditing(false);
                                                        setEditedTask(null);
                                                    }}>Cancel</StyledCancelButton>
                                                    <StyledDeleteButton onClick={() => handleDeleteTask(task._id)}>Delete</StyledDeleteButton>
                                                </>
                                            ) : (
                                                <Button 
                                                style={{
                                                            background: 'linear-gradient(45deg, #4CAF50 30%, #388E3C 90%)',
                                                            color: 'white',
                                                            margin: '8px',
                                                }}
                                                variant="outlined" color="primary" onClick={() => handleEditClick(task)}>
                                                    Edit
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    
            )}

            <ToastContainer position="top-center" autoClose={5000} />

 
        
        </>

    );
};

export default TaskTable;
