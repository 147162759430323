import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    CircularProgress,
    Typography,
    Grid,
    TextField,
    Button,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

function formatCurrency(amount) {
    return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'GNF',
        minimumFractionDigits: 2,
    });
}

function BudgetInfo() {
    const [revenuData, setRevenuData] = useState([]);
    const [expensesData, setExpensesData] = useState([]);
    const [subventionData, setSubventionData] = useState([]);
    const [fournisseurData, setFournisseurData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const authToken = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/expenses/displayAll`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                // const sortedExpenses = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));

                const today = new Date();
                const currentYear = today.getFullYear();

                const sortedExpenses = response.data.filter(expense => {
                    const expenseDate = new Date(expense.date);
                    return expenseDate.getFullYear() === currentYear;
                }).sort((a, b) => new Date(b.date) - new Date(a.date));


                // Apply date range filtering
                const filteredExpenses = sortedExpenses.filter(expense => {
                    const expenseDate = new Date(expense.date);
                    return (!startDate || expenseDate >= startDate) && (!endDate || expenseDate <= endDate);
                });

                const revenuExpenses2 = filteredExpenses.filter(expense => expense.type === 'revenu');
                const revenuExpenses = filteredExpenses.filter(
                    (expense) => expense.type !== 'subvention'
                        && expense.transactionType !== 'reduced'
                        && expense.type === 'revenu'
                );
                const expensesExpenses = filteredExpenses.filter(
                    expense => expense.type !== 'revenu' &&
                        expense.type !== 'subvention' &&
                        expense.type !== 'Paiement Fournisseur' &&
                        expense.type !== '');
                const subventionExpenses = filteredExpenses.filter(expense => expense.type === 'subvention');
                const fournisseurExpenses = filteredExpenses.filter(expense => expense.type === 'Paiement Fournisseur');

                setRevenuData(revenuExpenses);
                setExpensesData(expensesExpenses);
                setSubventionData(subventionExpenses);
                setFournisseurData(fournisseurExpenses);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
                toast.error('Error fetching data', {
                    position: 'top-center',
                    autoClose: 5000,
                });
            }
        };

        fetchData();
    }, [startDate, endDate]);

    const handleResetFilters = () => {
        setStartDate(null);
        setEndDate(null);
    };

 
    // // Calculate total sum of revenu, expenses, and subvention data
    // const totalRevenu = revenuData.reduce((total, expense) => total + expense.item.total, 0);
    // const totalExpenses = expensesData.reduce((total, expense) => total + expense.item.total, 0);
    // const totalSubvention = subventionData.reduce((total, expense) => total + expense.item.total, 0);
    // const totalFournisseur = fournisseurData.reduce((total, expense) => total + expense.item.total, 0);

    // const totalSolde = totalRevenu + totalSubvention - totalExpenses - totalFournisseur;

    // Get current date
    const currentDate = new Date();
    // Set the current date to the first day of the month
    currentDate.setDate(1);
    // Get the first day of the month
    const firstDayOfMonth = new Date(currentDate);
    // Get the last day of the month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Calculate total sum of revenu, expenses, and subvention data
    const totalRevenu = revenuData.reduce((total, expense) => {
        // Check if expense date is within the current month
        const expenseDate = new Date(expense.date);
        if (expenseDate >= firstDayOfMonth && expenseDate <= lastDayOfMonth) {
            return total + expense.item.total;
        }
        return total;
    }, 0);

    const totalExpenses = expensesData.reduce((total, expense) => {
        // Check if expense date is within the current month
        const expenseDate = new Date(expense.date);
        if (expenseDate >= firstDayOfMonth && expenseDate <= lastDayOfMonth) {
            return total + expense.item.total;
        }
        return total;
    }, 0);

    const totalSubvention = subventionData.reduce((total, expense) => {
        // Check if expense date is within the current month
        const expenseDate = new Date(expense.date);
        if (expenseDate >= firstDayOfMonth && expenseDate <= lastDayOfMonth) {
            return total + expense.item.total;
        }
        return total;
    }, 0);

    const totalFournisseur = fournisseurData.reduce((total, expense) => {
        // Check if expense date is within the current month
        const expenseDate = new Date(expense.date);
        if (expenseDate >= firstDayOfMonth && expenseDate <= lastDayOfMonth) {
            return total + expense.item.total;
        }
        return total;
    }, 0);

    const totalSolde = (totalRevenu + totalSubvention) - ( totalExpenses + totalFournisseur);

 
    return (
        <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } sm={ 6 }>
                <TextField
                    style={ {
                        width: '100%'
                    } }
                    label="Start Date"
                    type="date"
                    value={ startDate ? startDate.toISOString().split('T')[0] : '' }
                    onChange={ (e) => setStartDate(new Date(e.target.value)) }
                    InputLabelProps={ {
                        shrink: true,
                    } }
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
                <TextField
                    style={ {
                        width: '100%'
                    } }
                    label="End Date"
                    type="date"
                    value={ endDate ? endDate.toISOString().split('T')[0] : '' }
                    onChange={ (e) => setEndDate(new Date(e.target.value)) }
                    InputLabelProps={ {
                        shrink: true,
                    } }
                />
            </Grid>
            <Grid item xs={ 12 } sm={ 12 }>

                <Button
                    style={ {
                        width: '100%',
                        backgroundColor: '#EC7063'
                    } }

                    variant="contained" onClick={ handleResetFilters }>Reset Filters</Button>
            </Grid>
            { isLoading ? (
                <CircularProgress />
            ) : (
                <>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={ {
                            margin: '20px',
                            width: '30%',
                            backgroundColor: 'green',
                            color: 'white',
                            padding: '20px',
                            borderRadius: '10px',
                            textAlign: 'center',
                        } }
                        item
                        xs={ 12 }
                        sm={ 12 }
                    >
                        <Typography variant="h6" style={ styles.sectionTitle }>
                            Solde : { formatCurrency(totalSolde) }
                        </Typography>
                    </Grid>


                    <Grid item xs={ 12 } sm={ 4 }>
                        <Typography variant="h6" style={ styles.sectionTitle }>
                            Revenu  
                        </Typography>
                        <Typography variant="body1">
                            Total Revenu: { formatCurrency(revenuData.reduce((total, expense) => total + expense.item.total, 0)) }
                        </Typography>

                    </Grid>
                    <Grid item xs={ 12 } sm={ 4 }>
                        <Typography variant="h6" style={ styles.sectionTitle }>
                            Expense  
                        </Typography>
                        <Typography variant="body1">
                            Total Expense: { formatCurrency(expensesData.reduce((total, expense) => total + expense.item.total, 0)) }
                        </Typography>

                    </Grid>
                    <Grid item xs={ 12 } sm={ 4 }>
                        <Typography variant="h6" style={ styles.sectionTitle }>
                            Loan
                        </Typography>
                        <Typography variant="body1">
                            Total Loan: { formatCurrency(subventionData.reduce((total, expense) => total + expense.item.total, 0)) }
                        </Typography>

                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                        <Typography variant="h6" style={ styles.sectionTitle }>
                            Supplier Payment  
                        </Typography>
                        <Typography variant="body1">
                            Total Supplier Payment: { formatCurrency(fournisseurData.reduce((total, expense) => total + expense.item.total, 0)) }
                        </Typography>

                    </Grid>





                </>
            ) }
            <ToastContainer position="top-center" />
        </Grid>
    );
}

const styles = {
    sectionTitle: {
        marginBottom: '16px',
        fontWeight: 'bold',
    },
};

export default BudgetInfo;