import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import the Navbar component
// import LoginPage from './components/Login';
import LoginPage from './components/Login2';
import DashboardAppPage from './components/Dashboard';
import ExpenseTable from './components/TableExpense';
import RevenuTable from './components/TableRevenu';
import SubventionsTable from './components/TableSubventions';
import TablePaiementFournisseur from './components/TablePaiementFournisseur';

import TasksTable from './components/TasksTable';
import ExpenseForm from './forms/ExpenseForm';
import RevenusForm from './forms/RevenusForm';
import SubventionForm from './forms/SubventionForm';
import PaiementFournisseurForm from './forms/PaiementFournisseurForm';
import SubvensionRembourseForm from './forms/SubvensionRembourseForm';




import CreateTicketForm from './forms/CreateTicketForm';
import RemoveAccessForm from './forms/RemoveAccessForm';
import ResetUserPasswordForm from './forms/ResetUserPasswordForm';
import CreateForgotPasswordTicketForm from './forms/CreateForgotPasswordTicketForm';

import GoodsForm from './forms/GoodsForm';
import GoodsTable from './components/GoodsTable';
import GoodsTableForUser from './components/GoodsTableForUser';
import ITRevenuTable from './components/ITRevenuTable';

import GoodsTableForSender from './components/GoodsTableForSender';
import Register from './components/Register';
import UserTable from './components/UserTable';
import ExpensesInfo from './components/ChartGoodsStatus';
import Settings from './components/Settings';
import UserTicketsTable from './components/UserTicketsTable';
import UserTicketsTableForUsers from './components/UserTicketsTableForUsers';
import ClientsPage from './components/ClientsPage';
import TableSubvensionRembourse from './components/TableSubvensionRembourse';




import HomePage from './components/frontend/HomePage'
import Navbar2 from './components/frontend/Navbar'
import EmployeesTable from './components/EmployeesTable';
import SalaryTable from './components/SalaryTable';
import EmployeeForm from './forms/EmployeeForm';
import SalaryForm from './forms/SalaryForm';
import AssetTable from './components/AssetTable';
import AssetForm from './forms/AssetForm';





export default function Router() {
    const routes = useRoutes([
        {
            path: '/dashboard',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <DashboardAppPage />
                    </div>
                </>
            ),
            children: [],
        },


        

        {
            path: '/SubvensionRembourseForm',
            element: (
                <>
                    {/* Include the Navbar component */ }
                    <Navbar />
                    <div style={ { marginTop: '94px', padding: '10px' } }> {/* Add margin here */ }
                        <SubvensionRembourseForm />
                    </div>
                </>
            ),
            children: [],
        },


        {
            path: '/TableSubvensionRembourse',
            element: (
                <>
                    {/* Include the Navbar component */ }
                    <Navbar />
                    <div style={ { marginTop: '94px', padding: '10px' } }> {/* Add margin here */ }
                        <TableSubvensionRembourse />
                    </div>
                </>
            ),
            children: [],
        },

        {
            path: '/PaiementFournisseurForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <PaiementFournisseurForm />
                    </div>
                </>
            ),
            children: [],
        },
        {
            path: '/SubventionForm',
            element: (
                <>
                    {/* Include the Navbar component */ }
                    <Navbar />
                    <div style={ { marginTop: '94px', padding: '10px' } }> {/* Add margin here */ }
                        <SubventionForm />
                    </div>
                </>
            ),
            children: [],
        },

        {
            path: '/RevenusForm',
            element: (
                <>
                    {/* Include the Navbar component */ }
                    <Navbar />
                    <div style={ { marginTop: '94px', padding: '10px' } }> {/* Add margin here */ }
                        <RevenusForm />
                    </div>
                </>
            ),
            children: [],
        },
        
        
         {

             path: '/TablePaiementFournisseur',
            element: (
                <>
                    {/* Include the Navbar component */ }
                    <Navbar />
                    <div style={ { marginTop: '94px', padding: '10px' } }> {/* Add margin here */ }
                        <TablePaiementFournisseur />
                    </div>
                </>
            ),
            children: [],
        },
        
        {

            path: '/SubventionsTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <SubventionsTable />
                    </div>
                </>
            ),
            children: [],
        },

        {

            path: '/RevenuTable',
            element: (
                <>
                    {/* Include the Navbar component */ }
                    <Navbar />
                    <div style={ { marginTop: '94px', padding: '10px' } }> {/* Add margin here */ }
                        <RevenuTable />
                    </div>
                </>
            ),
            children: [],
        },

        {
            path: '/',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar2 />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <HomePage />
                    </div>
                </>
            )
        },

        {
            path: '/dashbaord',
            element: (
                <>
                    {/* Include the Navbar component */ }
                    <Navbar />
                    <div style={ { marginTop: '94px', padding: '10px' } }> {/* Add margin here */ }
                        <DashboardAppPage />
                    </div>
                </>
            )
        },

        {
            path: 'ITRevenuTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <ITRevenuTable />
                    </div>
                </>
            )
        },
        {
            path: 'ClientsPage',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <ClientsPage />
                    </div>
                </>
            )
        },


         


        
        {
            path: 'createForgotPasswordTicketForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <CreateForgotPasswordTicketForm />
                    </div>
                </>
            )
        },
         {
            path: 'removeAccessForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <RemoveAccessForm />
                    </div>
                </>
            )
        },

        {
            path: 'userTicketsTableForUsers',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <UserTicketsTableForUsers />
                    </div>
                </>
            )
        },

        {
            path: 'userTicketsTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <UserTicketsTable />
                    </div>
                </>
            )
        },

        {
            path: 'ExpensesInfo',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <ExpensesInfo />
                    </div>
                </>
            )
        },
        
        
        {
            path: 'resetUserPasswordForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <ResetUserPasswordForm />
                    </div>
                </>
            )
        },
        
        {
            path: 'adminSettings',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <Settings />
                    </div>
                </>
            )
        },
        {
            path: 'login',
            element: (
                <>
                    {/* Include the Navbar component */}
                    {/* <Navbar /> */}
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <LoginPage />
                    </div>
                </>
            )
        },
       
        {
            path: 'expensesTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <ExpenseTable />
                    </div>
                </>
            )
        },

        {
            path: 'TasksTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <TasksTable />
                    </div>
                </>
            )
        },

        {
            path: 'userTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <UserTable />
                    </div>
                </>
            )
        },
        {
            path: 'expenseForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <ExpenseForm />
                    </div>
                </>
            )
        },

        {
            path: 'createTicketForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <CreateTicketForm />
                    </div>
                </>
            )
        },

        {
            path: 'goodsForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <GoodsForm />
                    </div>
                </>
            )
        },
        {
            path: 'goodsTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <GoodsTable />
                    </div>
                </>
            )
        },
        {
            path: 'goodsTableForUser',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <GoodsTableForUser />
                    </div>
                </>
            )
        },
        {
            path: 'goodsTableForSender',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <GoodsTableForSender />
                    </div>
                </>
            )
        },

        // employee Table

        {
            path: 'employeeTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <EmployeesTable />
                    </div>
                </>
            )
        },

         // Employee Form

         {
            path: 'employeeForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <EmployeeForm />
                    </div>
                </>
            )
        },
        // salary Table

        {
            path: 'salaryTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <SalaryTable />
                    </div>
                </>
            )
        },

        // Salary Form

        {
            path: 'salaryForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <SalaryForm />
                    </div>
                </>
            ),
        },

        // Assets 

        {
            path: 'AssetTable',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <AssetTable />
                    </div>
                </>
            )
        },

        // Asse Form

        {
            path: 'AssetForm',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <AssetForm />
                    </div>
                </>
            )
        },

       
        {
            path: 'register',
            element: (
                <>
                    {/* Include the Navbar component */}
                    <Navbar />
                    <div style={{ marginTop: '94px', padding: '10px' }}> {/* Add margin here */}
                        <Register />
                    </div>
                </>
            )
        },
        

        
        

    ]);

    return routes;
}
