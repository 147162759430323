import React, { useState,useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { useTranslation } from 'react-i18next';


import PasswordChangeForm from '../forms/PasswordChangeForm';
import ExpenseForm from '../forms/ExpenseForm';
import GoodsForm from '../forms/GoodsForm';
import CreateTicketForm from '../forms/CreateTicketForm';
import RemoveAccessForm from '../forms/RemoveAccessForm';
import ResetUserPasswordForm from '../forms/ResetUserPasswordForm';
import ImageProfieChangeForm from '../forms/ImageProfieChangeForm';
import UserNameChangeForm from '../forms/UserNameChangeForm';


// import PinCodeChangeForm from './PinCodeChangeForm';
// import ResetUserPassword from './ResetUserPassword';
// import AvatarLinkUpdate from './AvatarLinkUpdate';


export default function App() {


    const userRole = localStorage.getItem('userRole');
    const userType = localStorage.getItem('userType');
    const userSpecialRole = localStorage.getItem('userSpecialRole');
    const { t } = useTranslation();
    // Define an array of menu items based on user role and type
    const menuItems = [
        <MenuItem value="createTicketForm">Ticket</MenuItem>,
        <MenuItem value="passwordChange">Change Your Password</MenuItem>,
        <MenuItem value="ImageProfieChangeForm">Change Image Profile</MenuItem>,
        <MenuItem value="UserNameChangeForm"> Update User Name</MenuItem>

        
    ];

  

    if (userSpecialRole === 'IT' || 'admin') {
        menuItems.push(
            <MenuItem value="removeAccessForm">Remove AccessForm</MenuItem>,
            <MenuItem value="resetUserPasswordForm">Reset User's Password</MenuItem>
        );
    }


 
   

    // Set the default form based on user type
    const defaultForm = userRole === 'hr' ? 'passwordChange' : 'createTicketForm';
    const [selectedForm, setSelectedForm] = useState(defaultForm);

    // Event handler for form selection
    const handleChange = (event) => {
        setSelectedForm(event.target.value);
    };


    return (
        <Box mt={4} mx={2}>
            <FormControl fullWidth>
                <InputLabel htmlFor="select-form">{t("SelectForm")}</InputLabel>
                <Select
                    labelId="select-form"
                    id="select-form"
                    value={selectedForm}
                    onChange={handleChange}
                >
                    {menuItems}
                </Select>
            </FormControl>

            <Box mt={5}>
                {selectedForm === 'passwordChange' && (
                    <PasswordChangeForm />
                )}     
            
                {selectedForm === 'ImageProfieChangeForm' && (
                    <ImageProfieChangeForm />
                )}
                

                {selectedForm === 'UserNameChangeForm' && (
                    <UserNameChangeForm />
                )}
                {selectedForm === 'createTicketForm' && (
                    <CreateTicketForm />
                )}

                {selectedForm === 'removeAccessForm' && (
                    <RemoveAccessForm />
                )}

                {selectedForm === 'resetUserPasswordForm' && (
                    <ResetUserPasswordForm />
                )}
                

                

                
                {/* {selectedForm === 'pinCodeChange' && (
                    <PinCodeChangeForm />
                )}
                {selectedForm === 'resetUserPassword' && (
                    <ResetUserPassword />
                )}
                {selectedForm === 'avatarLinkUpdate' && (
                    <AvatarLinkUpdate />
                )} */}
            </Box>

        </Box>
    );
}
