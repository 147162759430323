import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    Grid,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    Box,
    ButtonGroup,
    TextField,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import CreateTicketFrom from '../forms/CreateTicketForm';

function UserTicketTable() {
    const [userTickets, setUserTickets] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [filterType, setFilterType] = useState('');
    const [newTicket, setNewTicket] = useState(false);
    const [showCreateTicketForm, setShowCreateTicketForm] = useState(false);
    const [editModeMap, setEditModeMap] = useState({});
    const [commentMap, setCommentMap] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const authToken = localStorage.getItem('token');

    useEffect(() => {
        fetchUserTickets();
    }, [authToken]);

    const fetchUserTickets = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/ticket/listForUser`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            
            const tickets = response.data.userTickets;
            setUserTickets(tickets);
            
            // Initialize comment map with existing comments
            const initialCommentMap = {};
            tickets.forEach(ticket => {
                initialCommentMap[ticket._id] = ticket.userComment || '';
            });
            setCommentMap(initialCommentMap);
        } catch (error) {
            toast.error('Try to logout And Login Back', {
                position: 'top-center',
                autoClose: 5000,
            });
            console.error('Error fetching user tickets:', error);
        }
    };

    const handleEditClick = (ticketId, currentComment) => {
        setEditModeMap(prev => ({ ...prev, [ticketId]: true }));
        setCommentMap(prev => ({ ...prev, [ticketId]: currentComment || '' }));
    };

    const handleCancelEdit = (ticketId) => {
        setEditModeMap(prev => ({ ...prev, [ticketId]: false }));
        // Reset comment to original value
        const ticket = userTickets.find(t => t._id === ticketId);
        setCommentMap(prev => ({ ...prev, [ticketId]: ticket.userComment || '' }));
    };

    const handleCommentChange = (ticketId, newComment) => {
        setCommentMap(prev => ({ ...prev, [ticketId]: newComment }));
    };

    const handleSaveComment = async (ticketId) => {
        const comment = commentMap[ticketId];
        if (!comment?.trim()) {
            toast.error('Please enter a comment');
            return;
        }
    
        setIsSubmitting(true);
        try {
            // Use the correct API endpoint that matches your backend controller
            const url = `${process.env.REACT_APP_BASE_URL}/ticket/updateUserComment/${ticketId}`;
    
            // Make the API call
            const response = await axios.put(
                url,
                { userComment: comment },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.data.success) {
                toast.success('Comment updated successfully');
                setEditModeMap(prev => ({ ...prev, [ticketId]: false }));
                
                // After successful API call, update the state again with server response
                setUserTickets(prev =>
                    prev.map(ticket =>
                        ticket._id === ticketId
                            ? { 
                                ...ticket, 
                                userComment: comment,
                                lastUpdated: response.data.ticket?.lastUpdated || new Date().toISOString()
                              }
                            : ticket
                    )
                );
                
                // Optional re-fetch to ensure data consistency
                fetchUserTickets();
            } else {
                toast.error(response.data.message || 'Failed to update comment');
            }
        } catch (error) {
            console.error('Error updating comment:', error);
            
            const errorMessage = error.response?.data?.message || 'Failed to update comment';
            toast.error(errorMessage);
            
            // Revert the optimistic update in case of error
            const ticket = userTickets.find(t => t._id === ticketId);
            if (ticket) {
                setUserTickets(prev =>
                    prev.map(t =>
                        t._id === ticketId ? { ...ticket } : t
                    )
                );
                setCommentMap(prev => ({ ...prev, [ticketId]: ticket.userComment || '' }));
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#FFCC80'; // Light Orange
            case 'processing':
                return '#FFFF99'; // Light Yellow
            case 'completed':
                return '#B2FF66'; // Light Green
            case 'cancelled':
                return '#FF9999'; // Light Red
            default:
                return '#E0E0E0'; // Light Gray (default)
        }
    };

    const handleNewTicketClick = () => {
        setNewTicket(true);
        setShowCreateTicketForm(true);
    };

    const handleSeeMyTicketsClick = () => {
        setNewTicket(false);
        setShowCreateTicketForm(false);
        // Refresh tickets when returning to the tickets view
        fetchUserTickets();
    };

    const formatDateToFrench = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Date invalide';
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    const filteredTickets = userTickets
        .filter(ticket => 
            (!filterStatus || ticket.status === filterStatus) && 
            (!filterType || ticket.typeOfTicket === filterType)
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    const statusCounts = filteredTickets.reduce((acc, ticket) => {
        acc[ticket.status] = (acc[ticket.status] || 0) + 1;
        return acc;
    }, {
        pending: 0,
        processing: 0,
        completed: 0,
        cancelled: 0
    });

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                My Tickets
            </Typography>
            <div>
                {showCreateTicketForm ? (
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSeeMyTicketsClick}
                        >
                            See My Tickets
                        </Button>
                        <CreateTicketFrom />
                    </>
                ) : (
                    <>
                        <Button
                            style={{
                                marginBottom: '10px',
                            }}
                            variant="contained"
                            color="primary"
                            onClick={handleNewTicketClick}
                        >
                            New Ticket
                        </Button>
                        <br />
                        <Typography variant="h6" gutterBottom style={{textAlign: 'center'}}>
                            Tickets Status 
                        </Typography>
                        <Box spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup display='flex' spacing={2} gutterBottom>
                                <Button variant='contained' size='medium' color='warning'>Pending</Button>
                                <Button variant='contained' size='medium' style={{ backgroundColor: '#FFCC80' }}>Processing</Button>
                                <Button variant='contained' size='medium' color='success'>Completed</Button>
                                <Button variant='contained' size='medium' color='error'>Canceled</Button>
                            </ButtonGroup>
                        </Box>
                    </>
                )}
            </div>

            {!newTicket && (
                <>
                    <FormControl style={{ minWidth: 150, marginRight: 20 }}>
                        <InputLabel style={{ backgroundColor: 'white', padding: '0 5px' }}>
                            Status
                        </InputLabel>
                        <Select
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="processing">Processing</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="cancelled">Cancelled</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl style={{ minWidth: 150 }}>
                        <InputLabel style={{ backgroundColor: 'white', padding: '0 5px' }}>
                            Type of Ticket
                        </InputLabel>
                        <Select
                            value={filterType}
                            onChange={(e) => setFilterType(e.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="access">Access</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>

                    <Grid container spacing={2} style={{ marginTop: '10px' }}>
                        {Object.entries(statusCounts).map(([status, count]) => (
                            <Grid item xs={3} sm={3} key={status}>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {`${status}: ${count}`}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>

                    <TableContainer 
                        style={{
                            height: '750px',
                            marginTop: '20px'
                        }}
                        component={Paper}
                    >
                        <Table>
                            <TableHead
                                style={{
                                    backgroundColor: '#78AEF9',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1000,
                                }}
                            >
                                <TableRow>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Type</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Tel</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Content</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Admin Comment</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>User Comment</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Date</TableCell>
                                    <TableCell style={{ fontSize: '0.96rem', fontFamily: 'monospace', fontWeight: 'bold' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTickets.map((ticket) => (
                                    <TableRow
                                        key={ticket._id}
                                        style={{ backgroundColor: getStatusColor(ticket.status) }}
                                    >
                                        <TableCell>{ticket.name}</TableCell>
                                        <TableCell>{ticket.status}</TableCell>
                                        <TableCell>{ticket.typeOfTicket}</TableCell>
                                        <TableCell>{ticket.userName}</TableCell>
                                        <TableCell>{ticket.userTel}</TableCell>
                                        <TableCell>{ticket.ticketContent}</TableCell>
                                        <TableCell>{ticket.adminComment}</TableCell>
                                        <TableCell>
                                            {editModeMap[ticket._id] ? (
                                                <TextField
                                                    multiline
                                                    rows={2}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={commentMap[ticket._id] || ''}
                                                    onChange={(e) => handleCommentChange(ticket._id, e.target.value)}
                                                    placeholder="Add your feedback here..."
                                                    disabled={isSubmitting}
                                                />
                                            ) : (
                                                <div>{ticket.userComment || 'No feedback provided'}</div>
                                            )}
                                        </TableCell>
                                        <TableCell>{formatDateToFrench(ticket.date)}</TableCell>
                                        <TableCell>
                                            {editModeMap[ticket._id] ? (
                                                <Box>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => handleSaveComment(ticket._id)}
                                                        disabled={isSubmitting}
                                                        style={{ marginRight: '8px', marginBottom: '4px' }}
                                                    >
                                                        {isSubmitting ? 'Saving...' : 'Save'}
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() => handleCancelEdit(ticket._id)}
                                                        disabled={isSubmitting}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleEditClick(ticket._id, ticket.userComment)}
                                                >
                                                    {ticket.userComment ? 'Edit Feedback' : 'Add Feedback'}
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ToastContainer position="top-center" />
                </>
            )}
        </Container>
    );
}

export default UserTicketTable;