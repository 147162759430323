import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, CircularProgress, TextField, Typography, Button
} from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadModal from '../modals/UploadModal';

const AssetTable = () => {
    const [assetList, setAssetList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({ assetNumber: '', dateBought: '', boughtBy: '' });
    const [showFilters, setShowFilters] = useState(false);
    const [editedAsset, setEditedAsset] = useState({});
    const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for upload modal
    const [modalAssetId, setModalAssetId] = useState(''); // State for the employeeID
    const [modalAssetText, setModalAssetText] = useState(''); // State for the target image title
    const [updateTable, setUpdateTable] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/asset/getAll`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            if (response.status === 200) {
                setAssetList(response.data);
            } else {
                toast.error('Error fetching assets');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
            toast.error('Failed to load assets');
        } finally {
            setIsLoading(false);
        }
    };

    const toggleEdit = (assetId) => {
        setCurrentlyEditedRow(assetId === currentlyEditedRow ? null : assetId);
        setEditedAsset({ ...editedAsset, [assetId]: {} });
    };

    const saveEditedAsset = async (assetId) => {
            const authToken = localStorage.getItem('token');
            const editedData = editedAsset[assetId];
        
            console.log(`editedData ${JSON.stringify(editedData)}`);
            console.log(`employeeId ${JSON.stringify(assetId)}`);
        
            try {
                await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/asset/update/${assetId}`, 
                    {
                        ...editedData,
                        assetId: assetId, // Ensure employeeId is included in the request
                    }, 
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
        
                // Show success message
                toast.success('Asset updated successfully', {
                    position: 'top-center',
                    autoClose: 3000,
                });
        
                // Refresh the data
                fetchData();
        
                // Toggle off editing mode
                toggleEdit();
        
            } catch (error) {
                console.error('Error updating asset:', error);
                
                // Show error message to user
                toast.error('Failed to update asset. Please try again.', {
                    position: 'top-center',
                    autoClose: 5000,
                });
            }
        };

            const handleDeleteAsset = async (assetId) => {
                try {
                    const accessToken = localStorage.getItem('token');
                    if (!accessToken) {
                        throw new Error('No access token found in localStorage');
                    }
        
                    const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/asset/delete/${assetId}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
        
                    if (response.status === 200) {
        
                        // Reload the current page after a successful delete
                        window.location.reload();
                        // Remove the deleted item from the goodsList
                        setAssetList((prevAssetList) => prevAssetList.filter((asset) => asset.id !== assetId));
                    } else {
                        console.error('Failed to delete asset:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error deleting asset:', error);
                }
            };

        const handleResetFilters = () => {
            setFilters(initialFilters);
        };

        const initialFilters = {
            assetNumber: '',
            dateBought: '',
            boughtBy: '',
        };

        const handleEditChange = (assetId, field, value) => {
            setEditedAsset((prevEditedAsset) => ({
                ...prevEditedAsset,
                [assetId]: {
                    ...prevEditedAsset[assetId],
                    [field]: value,
                },
            }));
        };
        

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilters({ ...filters, [event.target.name]: event.target.value });
    };

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(assetList);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Assets');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'assets.xlsx');
    };

const filteredAssetList = assetList.filter(asset =>
    asset?.assetName?.toLowerCase().includes(search.toLowerCase()) &&
    (filters.assetNumber ? asset?.assetNumber?.toLowerCase().includes(filters.assetNumber.toLowerCase()) : true) &&
    (filters.dateBought ? asset?.dateBought?.includes(filters.dateBought) : true) &&
    (filters.boughtBy ? asset?.boughtBy?.toLowerCase().includes(filters.boughtBy.toLowerCase()) : true)
);

const formatDateToFrench = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Date invalide'; // Return 'Invalid date' in French
    return date.toLocaleDateString('fr-FR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
};

const cancelEdit = (rowId) => {
    // Remove the row from the editedGoods state
    const newEditedAsset = { ...editedAsset };
    delete newEditedAsset[rowId];
    setEditedAsset(newEditedAsset);

    // Reset the currentlyEditedRow state
    setCurrentlyEditedRow(null);
};

    const handleImageClick = (imageUrl, rowId) => {
        setSelectedImage(imageUrl);
        setSelectedRowId(rowId);
        console.log(`rowId ${rowId}`);
    };

    const handleUploadButtonClick = (assetID, targetImageTitle) => {
        setModalAssetId(assetID); // Set the employeeID for the modal
        setModalAssetText(targetImageTitle); // Set the target image title for the modal
        setIsUploadModalOpen(true); // Open the upload modal
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false); // Close the upload modal
        fetchData(); // Refresh the data to display the uploaded image
    };

    return (
        <div style={{ margin: '0 auto', maxWidth: '1600px' }}>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '20px 0', fontWeight: 'bold'}}>Assets</Typography>

            <Button variant="contained" color="secondary" onClick={() => setShowFilters(!showFilters)}>
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>

            {showFilters && (
                <div style={{ display: 'flex', gap: '10px', margin: '20px 0' }}>
                    <TextField label="Asset Number" name="assetNumber" variant="outlined" value={filters.assetNumber} onChange={handleFilterChange} />
                    <TextField label="Date Bought" name="dateBought" type="date" variant="outlined" value={filters.dateBought} onChange={handleFilterChange} InputLabelProps={{ shrink: true }} />
                    <TextField label="Bought By" name="boughtBy" variant="outlined" value={filters.boughtBy} onChange={handleFilterChange} />
                    <Button variant="contained" color="primary" onClick={handleExport} style={{ marginLeft: '10px' }}>Download</Button>
                    <Button variant="contained" color="error" onClick={handleResetFilters} style={{ marginLeft: '10px' }}>Reset Filters</Button>

                </div>
            )}
            <Button variant="contained" color="primary" onClick={() => navigate('/AssetForm')} style={{ marginLeft: '10px' }}>Add Asset</Button>
            <br />
            <br />
            <div> <h3>Total {filteredAssetList.length} </h3> </div>

             {isUploadModalOpen && (
                    <UploadModal assetID={modalAssetId}
                        setUpdateTables={ setUpdateTable }
            
                    modalAssetText={modalAssetText} onClose={handleUploadModalClose} />
                )}
            
            <ToastContainer />
            <TableContainer component={Paper} style={{ height: '750px' }}>
                <Table>
                    <TableHead style={{ backgroundColor: '#78AEF9', position: 'sticky', top: 0, zIndex: 99 }}>
                        <TableRow>
                            {["No", "Asset No", "Item Name", "Description", "Quantity", "Price", "Total", "Image", "Date Bought", "By Who", "Action"].map(header => (
                                <TableCell key={header} style={{ fontWeight: 'bold' }}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredAssetList.map((asset, index) => (
                                <TableRow key={asset._id}>
                                    <TableCell>{index + 1}</TableCell>

                                    <TableCell>
                                    {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={editedAsset[asset._id]?.assetNumber || asset.assetNumber}
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'assetNumber', e.target.value)
                                            }
                                        />
                                    ) : (
                                        asset.assetNumber
                                    )}
                                    </TableCell>

                                    <TableCell>
                                    {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={editedAsset[asset._id]?.assetName || asset.assetName}
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'assetName', e.target.value)
                                            }
                                        />
                                    ) : (
                                        asset.assetName
                                    )}
                                    </TableCell>

                                    <TableCell>
                                        {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={
                                                editedAsset[asset._id]?.assetDescription || asset.assetDescription
                                            }
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'assetDescription', e.target.value)
                                            }
                                        />
                                    ) : (

                                        asset.assetDescription
                                    )}
                                    </TableCell>

                                    <TableCell>
                                    {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={editedAsset[asset._id]?.quantity || asset.quantity}
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'quantity', e.target.value)
                                            }
                                        />
                                    ) : (
                                        asset.quantity
                                    )}
                                    </TableCell>

                                    <TableCell>
                                    {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={editedAsset[asset._id]?.assetPrice || asset.assetPrice}
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'assetPrice', e.target.value)
                                            }
                                        />
                                    ) : (
                                        asset.assetPrice
                                    )}
                                    </TableCell>

                                    <TableCell>
                                    {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={editedAsset[asset._id]?.assetTotal || asset.assetTotal}
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'assetTotal', e.target.value)
                                            }
                                        />
                                    ) : (
                                        asset.assetTotal
                                    )}
                                    </TableCell>

                                    <TableCell>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleUploadButtonClick(asset._id, 'assetImages')}
                                                style={{
                                                    borderRadius: '50%',  // Make the button round
                                                    width: '30px',        // Set the button width
                                                    height: '30px',       // Set the button height
                                                    padding: '0',         // Remove padding
                                                    minWidth: 'unset',    // Remove minWidth
                                                }}
                                            >
                                                +
                                            </Button>
                                            {asset.assetImages && asset.assetImages.map((imageLink, index) => (
                                                <img
                                                    key={index}
                                                    src={imageLink}
                                                    alt={`Image ${index}`}
                                                    style={{
                                                        display: 'inline-block',
                                                        maxWidth: '43px',
                                                        maxHeight: '43px',
                                                        marginRight: '5px',
                                                        borderRadius: '50px', // Set your desired border radius
                                                        marginTop: '0',      // Remove marginTop
                                                        marginBottom: '0'    // Remove marginBottom
                                                    }}
                                                    onClick={() => handleImageClick(imageLink, asset._id)}
                                                />
                                            ))}
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                    {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={editedAsset[asset._id]?.dateBought || asset.dateBought}
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'dateBought', e.target.value)
                                            }
                                        />
                                    ) : (
                                        formatDateToFrench(asset.dateBought)
                                    )}
                                    </TableCell>

                                    <TableCell>
                                    {currentlyEditedRow === asset._id ? (
                                        <TextField 
                                            value={editedAsset[asset._id]?.boughtBy || asset.boughtBy}
                                            onChange={(e) => 
                                                handleEditChange(asset._id, 'boughtBy', e.target.value)
                                            }
                                        />
                                    ) : (
                                        asset.boughtBy
                                    )}
                                    </TableCell>

                                     <TableCell>
                                                                {currentlyEditedRow === asset._id ? (
                                    
                                                                    <>
                                                                        <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => saveEditedAsset(asset._id)}
                                                                    >
                                                                        Save
                                                                    </Button>
                                    
                                    
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => cancelEdit(asset._id)}
                                                                            style={{ backgroundColor: 'darkred', color: 'white' , marginTop: '5px' }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            style={{ backgroundColor: 'red' }}
                                                                            onClick={() => {
                                                                                if (window.confirm('Are you sure you want to delete this asset?')) {
                                                                                    handleDeleteAsset(asset._id);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                    
                                                                    </>
                                                                    
                                    
                                                                    
                                                                ) : (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => toggleEdit(asset._id)}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                )}
                                                            </TableCell>
                                    
                                    {/* <TableCell style={{ display: 'flex', gap: '10px' }}>
                                        <Button variant='contained'>Edit</Button>
                                        <Button 
                                            variant='contained' 
                                            color='error'
                                            onClick={() => {
                                                if (window.confirm('Are you sure you want to delete this asset?')) {
                                                    handleDeleteAsset(asset._id);
                                                }
                                            }
                                        }
                                            >Delete</Button>

                                    </TableCell> */}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
                
        </div>
    );
};

export default AssetTable;
